(function(){
    function AtomicFiView (def,funnel) {
        AtomicFiView.SUPERconstructor.call(this,def,funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/atomic-fi"), AtomicFiView);

    AtomicFiView.prototype.wasRedirected = function () {
        return this.redirected;
    };

    AtomicFiView.prototype.startAction = function(selectedProduct, token) {
      var self = this;
      var confirmAction = new jsedn.Map();
      confirmAction.set(new jsedn.kw(":funnel-step-s12n-action/type"),
			new jsedn.kw(":funnel-step-s12n-action-type/confirm"));
      Atomic.transact({
        config: {
	  publicToken: token,
	  tasks: [{ product: selectedProduct }]
        },
        onInteraction: interaction => {
          console.log('Interaction event:', interaction.name, interaction.value)
        },
        onFinish: data => {
          console.log('Finish event:', data.taskId, data.handoff)
        },
        onClose: data => {
          console.log('Close event:', data);
          self.resultData = data;
          self.funnel.onAction(confirmAction,function(status){
          });
        }
      });
    }

    AtomicFiView.prototype.createContent = function (definition) {
      var self = this;
      var body = document.createElement("div");

      var actionType = definition[":action-type"];
      var accessToken = definition[":access-token"];
      var baseUrl = self.funnel && self.funnel.handler && self.funnel.handler.baseUrl() || "";
      //add script reference
      var s = document.createElement("script");
      s.type = 'text/javascript';
      s.src = baseUrl + "/sdk/javascript/atomic-fi/index.js";
      s.onload = function() {
        self.removeActions();
        var selectedProduct = Product.DEPOSIT;
        switch (actionType) {
         case ":deposit":
	      selectedProduct = Product.DEPOSIT;
	      break;
        }
        self.startAction(selectedProduct, accessToken);
      };
      document.body.appendChild(s);
      return body;
    };

   AtomicFiView.prototype.retrieveData = function (cb){
        var r = new jsedn.Map();
        if (this.error) {
          cb(true, r);
          return;
        }
        r.set(jsedn.kw(":transact-result"), this.resultData.reason)
        cb(true, r);
    }

    prisma_plugins = true;
})();
