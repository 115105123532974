(function() {

    // https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
    if (!String.prototype.includes) {
      String.prototype.includes = function(search, start) {
        'use strict';
        if (typeof start !== 'number') {
          start = 0;
        }

        if (start + search.length > this.length) {
          return false;
        } else {
          return this.indexOf(search, start) !== -1;
        }
      };
    }

    var isSafari = function() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
          if (ua.indexOf('chrome') > -1) {
            return false;
          } else {
            return true;
          }
      }
    }

    if(!("valueAsDate" in HTMLInputElement.prototype) || isSafari()){
        Object.defineProperty(HTMLInputElement.prototype, "valueAsDate", {
        get: function(){
            var d = this.value.split(/\D/);
            return new Date(d[0], --d[1], d[2]);
        },
        set: function(d){
            var day = ("0" + d.getUTCDate()).slice(-2),
            month = ("0" + (d.getUTCMonth() + 1)).slice(-2),
            datestr = d.getUTCFullYear()+"-"+month+"-"+day;
            this.value = datestr;
        }
      });
    }

    // Production steps of ECMA-262, Edition 5, 15.4.4.18
    // Reference: http://es5.github.com/#x15.4.4.18
    if (!Array.prototype.forEach) {

        Array.prototype.forEach = function forEach(callback, thisArg) {
            'use strict';
            var T, k;

            if (this == null) {
                throw new TypeError("this is null or not defined");
            }

            var kValue,
                // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
                O = Object(this),

                // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
                // 3. Let len be ToUint32(lenValue).
                len = O.length >>> 0; // Hack to convert O.length to a UInt32

            // 4. If IsCallable(callback) is false, throw a TypeError exception.
            // See: http://es5.github.com/#x9.11
            if ({}.toString.call(callback) !== "[object Function]") {
                throw new TypeError(callback + " is not a function");
            }

            // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length >= 2) {
                T = thisArg;
            }

            // 6. Let k be 0
            k = 0;

            // 7. Repeat, while k < len
            while (k < len) {

                // a. Let Pk be ToString(k).
                //   This is implicit for LHS operands of the in operator
                // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
                //   This step can be combined with c
                // c. If kPresent is true, then
                if (k in O) {

                    // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
                    kValue = O[k];

                    // ii. Call the Call internal method of callback with T as the this value and
                    // argument list containing kValue, k, and O.
                    callback.call(T, kValue, k, O);
                }
                // d. Increase k by 1.
                k++;
            }
            // 8. return undefined
        };
    }

    if (!Array.prototype.filter)
        Array.prototype.filter = function(func, thisArg) {
            'use strict';
            if ( ! ((typeof func === 'Function') && this) )
                throw new TypeError();

            var len = this.length >>> 0,
                res = new Array(len), // preallocate array
                c = 0, i = -1;
            if (thisArg === undefined)
                while (++i !== len)
                    // checks to see if the key was set
                    if (i in this)
                        if (func(t[i], i, t))
                            res[c++] = t[i];
                        else
                            while (++i !== len)
                                // checks to see if the key was set
                                if (i in this)
                                    if (func.call(thisArg, t[i], i, t))
                                        res[c++] = t[i];

            res.length = c; // shrink down array to proper size
            return res;
        };

    if (!Array.prototype.indexOf) {
      Array.prototype.indexOf = function indexOf(member, startFrom) {
        /*
        In non-strict mode, if the `this` variable is null or undefined, then it is
        set to the window object. Otherwise, `this` is automatically converted to an
        object. In strict mode, if the `this` variable is null or undefined, a
        `TypeError` is thrown.
        */
        if (this == null) {
          throw new TypeError("Array.prototype.indexOf() - can't convert `" + this + "` to object");
        }

        var
          index = isFinite(startFrom) ? Math.floor(startFrom) : 0,
          that = this instanceof Object ? this : new Object(this),
          length = isFinite(that.length) ? Math.floor(that.length) : 0;

        if (index >= length) {
          return -1;
        }

        if (index < 0) {
          index = Math.max(length + index, 0);
        }

        if (member === undefined) {
          /*
            Since `member` is undefined, keys that don't exist will have the same
            value as `member`, and thus do need to be checked.
          */
          do {
            if (index in that && that[index] === undefined) {
              return index;
            }
          } while (++index < length);
        } else {
          do {
            if (that[index] === member) {
              return index;
            }
          } while (++index < length);
        }

        return -1;
      };
    }

    // https://tc39.github.io/ecma262/#sec-array.prototype.find
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return kValue.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return kValue;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return undefined.
                return undefined;
            },
            configurable: true,
            writable: true
        });
    }

    // https://tc39.github.io/ecma262/#sec-array.prototype.findindex
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return k.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return k;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return -1.
                return -1;
            },
            configurable: true,
            writable: true
        });
    }

    // Production steps of ECMA-262, Edition 5, 15.4.4.21
    // Reference: http://es5.github.io/#x15.4.4.21
    // https://tc39.github.io/ecma262/#sec-array.prototype.reduce
    if (!Array.prototype.reduce) {
      Object.defineProperty(Array.prototype, 'reduce', {
        value: function(callback /*, initialValue*/) {
          if (this === null) {
            throw new TypeError( 'Array.prototype.reduce ' +
              'called on null or undefined' );
          }
          if (typeof callback !== 'function') {
            throw new TypeError( callback +
              ' is not a function');
          }

          // 1. Let O be ? ToObject(this value).
          var o = Object(this);

          // 2. Let len be ? ToLength(? Get(O, "length")).
          var len = o.length >>> 0;

          // Steps 3, 4, 5, 6, 7
          var k = 0;
          var value;

          if (arguments.length >= 2) {
            value = arguments[1];
          } else {
            while (k < len && !(k in o)) {
              k++;
            }

            // 3. If len is 0 and initialValue is not present,
            //    throw a TypeError exception.
            if (k >= len) {
              throw new TypeError( 'Reduce of empty array ' +
                'with no initial value' );
            }
            value = o[k++];
          }

          // 8. Repeat, while k < len
          while (k < len) {
            // a. Let Pk be ! ToString(k).
            // b. Let kPresent be ? HasProperty(O, Pk).
            // c. If kPresent is true, then
            //    i.  Let kValue be ? Get(O, Pk).
            //    ii. Let accumulator be ? Call(
            //          callbackfn, undefined,
            //          « accumulator, kValue, k, O »).
            if (k in o) {
              value = callback(value, o[k], k, o);
            }

            // d. Increase k by 1.
            k++;
          }

          // 9. Return accumulator.
          return value;
        }
      });
    }

    function linkify (content, options) {
		/* original code:
		 * 		https://github.com/cowboy/javascript-linkify
		 *      https://github.com/cowboy/javascript-linkify/blob/master/ba-linkify.js
		 *      http://benalman.com/code/projects/javascript-linkify/examples/linkify/?x=%3Ca+href%3D%22aaa%22+target%3D%22_blank%22%3Eaaa%3C%2Fa%3E
		 */

		if (typeof(content) !== 'string') {
			return content;
		}
		var SCHEME = "[a-z\\d.-]+://",
			IPV4 = "(?:(?:[0-9]|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.){3}(?:[0-9]|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])",
			HOSTNAME = "(?:(?:[^\\s!@#$%^&*()_=+[\\]{}\\\\|;:'\",.<>/?]+)\\.)+",
			TLD = "(?:ac|ad|aero|ae|af|ag|ai|al|am|an|ao|aq|arpa|ar|asia|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|biz|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|cat|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|coop|com|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|edu|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gov|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|info|int|in|io|iq|ir|is|it|je|jm|jobs|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lulv|ly|ma|mc|md|me|mg|mh|mil|mk|ml|mm|mn|mobi|mo|mp|mq|mr|ms|mt|museum|mu|mv|mw|mx|my|mz|name|na|nc|net|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|org|pa|pe|pf|pg|ph|pk|pl|pm|pn|pro|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tel|tf|tg|th|tj|tk|tl|tm|tn|to|tp|travel|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|xn--0zwm56d|xn--11b5bs3a9aj6g|xn--80akhbyknj4f|xn--9t4b11yi5a|xn--deba0ad|xn--g6w251d|xn--hgbk6aj7f53bba|xn--hlcj6aya9esc7a|xn--jxalpdlp|xn--kgbechtv|xn--zckzah|ye|yt|yu|za|zm|zw)",
			HOST_OR_IP = "(?:" + HOSTNAME + TLD + "|" + IPV4 + ")",
			PATH = "(?:[;/][^#?<>\\s]*)?",
			QUERY_FRAG = "(?:\\?[^#<>\\s]*)?(?:#[^<>\\s]*)?",
			URI1 = "\\b" + SCHEME + "[^<>\\s]+",
			URI2 = "\\b" + HOST_OR_IP + PATH + QUERY_FRAG + "(?!\\w)",

			MAILTO = "mailto:",
			EMAIL = "(?:" + MAILTO + ")?[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" + HOST_OR_IP + QUERY_FRAG + "(?!\\w)",
			LINK = "<a[^>]*href=\"([^\"]*)\"[^>]*>([^\"]*)<\/a>",
			LINK_RE = new RegExp( LINK, "ig" ),

			URI_RE = new RegExp( "(?:" + LINK + "|" + URI1 + "|" + URI2 + "|" + EMAIL + ")", "ig" ),
			SCHEME_RE = new RegExp( "^" + SCHEME, "i" ),
			EMAIL_RE = new RegExp( EMAIL ),
			URI1_RE = new RegExp( URI1 ),
			URI2_RE = new RegExp( URI2 ),

			quotes = {
			  "'": "`",
			  '>': '<',
			  ')': '(',
			  ']': '[',
			  '}': '{',
			  '»': '«',
			  '›': '‹'
			},

			default_options = {
			  callback: function( text, href ) {
				return href ? '<a href="' + href + '" title="' + href + '" target="_blank">' + text + '</a>' : text;
			  },
			  punct_regexp: /(?:[!?.,:;'"]|(?:&|&amp;)(?:lt|gt|quot|apos|raquo|laquo|rsaquo|lsaquo);)$/,
			  exclude: {email: false, uri: false}
			};

		options = options || {};

		// Temp variables.
		var arr,
			i,
			link,
			href,

			// Output HTML.
			html = '',

			// Store text / link parts, in order, for re-combination.
			parts = [],

			// Used for keeping track of indices in the text.
			idx_prev,
			idx_last,
			idx,
			link_last,

			// Used for trimming trailing punctuation and quotes from links.
			matches_begin,
			matches_end,
			quote_begin,
			quote_end;

		// Initialize options.
		for ( i in default_options ) {
			if ( options[ i ] === undefined ) {
				options[ i ] = default_options[ i ];
			}
		}

		var haveToExclude = function(link) {
			if (options.exclude.email) {
				if (EMAIL_RE.test(link)) {
					return true;
				}
			}
			if (options.exclude.uri) {
				if (URI1_RE.test(link)) {
					return true;
				}
				if (URI2_RE.test(link)) {
					return true;
				}
			}
			return false;
		};

		// Find links.
		while ( arr = URI_RE.exec( content ) )
		{
			link = arr[0];
			idx_last = URI_RE.lastIndex;
			idx = idx_last - link.length;

			// Not a link if preceded by certain characters.
			if ( /[\/:]/.test( content.charAt( idx - 1 ) ) ) {
				continue;
			}

			// validate link already.
			var link_match = !link.match(LINK_RE);
			if (!link_match) {
				continue;
			}

			// excludes.
			if (haveToExclude(link)) {
				continue;
			}

			// Trim trailing punctuation.
			do {
				// If no changes are made, we don't want to loop forever!
				link_last = link;

				quote_end = link.substr( -1 );
				quote_begin = quotes[ quote_end ];

				// Ending quote character?
				if ( quote_begin ) {
				  matches_begin = link.match( new RegExp( '\\' + quote_begin + '(?!$)', 'g' ) );
				  matches_end = link.match( new RegExp( '\\' + quote_end, 'g' ) );

				  // If quotes are unbalanced, remove trailing quote character.
				  if ( ( matches_begin ? matches_begin.length : 0 ) < ( matches_end ? matches_end.length : 0 ) ) {
					link = link.substr( 0, link.length - 1 );
					idx_last--;
				  }
				}

				// Ending non-quote punctuation character?
				if ( options.punct_regexp ) {
				  link = link.replace( options.punct_regexp, function(a){
					idx_last -= a.length;
					return '';
				  });
				}
			} while ( link.length && link !== link_last );

			href = link;

			// Add appropriate protocol to naked links.
			if ( !SCHEME_RE.test( href ) ) {
				href = (
					href.indexOf( '@' ) !== -1 ? ( !href.indexOf( MAILTO ) ? '' : MAILTO ) :
					!href.indexOf( 'irc.' ) ? 'irc://' :
					!href.indexOf( 'ftp.' ) ? 'ftp://' : 'http://'
				) + href;
			}

			// Push preceding non-link text onto the array.
			if ( idx_prev !== idx ) {
				parts.push([ content.slice( idx_prev, idx ) ]);
				idx_prev = idx_last;
			}
			// Push massaged link onto the array
			parts.push([ link, href ]);
		}

		// Push remaining non-link text onto the array.
		parts.push([ content.substr( idx_prev ) ]);

		// Process the array items.
		for ( i = 0; i < parts.length; i++ ) {
			var part = parts[i];
			html += options.callback( part[0], part[1] );
		}

		// In case of catastrophic failure, return the original text;
		return html || content;
	}
    function validCustomerId (customerId) {
        return ["undefined","null","0","NaN","false"].indexOf(customerId+'') == -1 ? customerId:"";
    }

    var readCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    };

     var createCookie = function(name, value, maxAgeSeconds) {
       var knownThreeWordDomains = [ "bci" ];
       var urlParts = window.location.hostname.split('.');
       var domain = "";
       if ((urlParts.length > 3 && isNaN(urlParts[0])) ||
                                   (urlParts.length == 3 &&
                                   (urlParts[urlParts.length - 1].length == 3 ||
                                    urlParts[urlParts.length - 2].length > 3 ||
                                    knownThreeWordDomains.indexOf(urlParts[urlParts.length - 2]) >= 0))) {
           urlParts.shift();
           domain = ";domain=" + urlParts.join(".");
       } else {
           domain = ";domain=" + window.location.hostname;
       }
       if (maxAgeSeconds > 0) {
            var date = new Date();
            date.setTime(date.getTime()+(maxAgeSeconds * 1000));
            var expires = "; expires="+date.toGMTString();
        } else {
            var expires = "";
        }
        if (window.location.protocol == 'https:') {
          var secure = ";secure";
        } else {
          var secure = "";
        }
        document.cookie = name+"="+value+expires+secure+"; path=/"+domain;
     };

    function trim (s){
         var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
         return s.replace(rtrim,'');
    }
    function decodeBase64 (s) {
        var e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;
        var A="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for(i=0;i<64;i++){e[A.charAt(i)]=i;}
        for(x=0;x<L;x++){
            c=e[s.charAt(x)];b=(b<<6)+c;l+=6;
            while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
        }
        return r;
    }

    function b64_to_utf8(str) {
      var str = str.replace(/\s/g, '');
       if (window.atob != undefined) {
         return decodeURIComponent(escape(window.atob(str)));
       }
       else {
         return Base64.decode(str);
      }
    }

    MODE_HORIZONTAL_SCROLL = 1;
    MODE_FADEIN_FADEOUT = 2;

    function hasClass(ele, cls) {
        return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    }
    function addClass(ele, cls) {
        if (!hasClass(ele, cls)) ele.className += " " + cls;
    }
    function removeClass(ele, cls) {
        if (hasClass(ele, cls)) {
            var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            ele.className = ele.className.replace(reg, ' ');
        }
    }
    function setEvent (element,event,func){
        if(element.addEventListener)
        {
            return element.addEventListener(event,func,false);
        }
        else if(element.attachEvent)
        {
            return element.attachEvent("on"+event,func);
        }
    }
    function detectswipe(ele,func) {
      swipe_det = new Object();
      swipe_det.sX = 0; swipe_det.sY = 0; swipe_det.eX = 0; swipe_det.eY = 0;
      var min_x = 30;  //min x swipe for horizontal swipe
      var max_x = 30;  //max x difference for vertical swipe
      var min_y = 50;  //min y swipe for vertical swipe
      var max_y = 60;  //max y difference for horizontal swipe
      var direc = "";
      setEvent(ele,'touchstart',function(e){
        var t = e.touches[0];
        swipe_det.sX = t.screenX;
        swipe_det.sY = t.screenY;
      });
      setEvent(ele,'touchmove',function(e){
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        var t = e.touches[0];
        swipe_det.eX = t.screenX;
        swipe_det.eY = t.screenY;
      });
      setEvent(ele,'touchend',function(e){
        //horizontal detection
        if ((((swipe_det.eX - min_x > swipe_det.sX) || (swipe_det.eX + min_x < swipe_det.sX)) && ((swipe_det.eY < swipe_det.sY + max_y) && (swipe_det.sY > swipe_det.eY - max_y) && (swipe_det.eX > 0)))) {
          if(swipe_det.eX > swipe_det.sX) direc = "r";
          else direc = "l";
        }
        //vertical detection
        else if ((((swipe_det.eY - min_y > swipe_det.sY) || (swipe_det.eY + min_y < swipe_det.sY)) && ((swipe_det.eX < swipe_det.sX + max_x) && (swipe_det.sX > swipe_det.eX - max_x) && (swipe_det.eY > 0)))) {
          if(swipe_det.eY > swipe_det.sY) direc = "d";
          else direc = "u";
        }

        if (direc != "") {
          if(typeof func == 'function') func(ele,direc);
        }
        direc = "";
        swipe_det.sX = 0; swipe_det.sY = 0; swipe_det.eX = 0; swipe_det.eY = 0;
      });
    }


    if (typeof prisma !== 'object') {
        prisma = {};
    }
    prisma.format = function(content) {
       for (var i=1; i < arguments.length; i++)
       {
           var replacement = '{' + (i-1) + '}';
           content = content.replace(replacement, arguments[i]);
       }
       return content;
    };
    function dateToFixedStrFormat (date){
      var year = date.getFullYear();
      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return   day + '/' + month + '/' + year;
    }
    prisma.dateFormat = "dd/MM/yyyy";
    prisma.dateMask = "00/00/0000";

    function stringToDate(str){
        var s = [];
        var d = new Date();
        switch(prisma.dateFormat){
            case "dd/MM/yyyy":
                s = str.split("/");
                d =  new Date(Date.parse(s[2]+ "-" + s[1] + "-" +s[0])+(d.getTimezoneOffset()*60000));
            break;
            case "MM/dd/yyyy":
                s = str.split("/");
                d = new Date(Date.parse(s[2]+ "-" + s[0] + "-" +s[1]));
            break;
            case "yyyy-MM-dd":
                s = str.split("-");
                d = new Date(Date.parse(s[0]+ "-" + s[1] + "-" +s[2]));
            break;
            default:
                s = str.split("-");
                d = new Date(Date.parse(s[2]+ "-" + s[0] + "-" +s[1]));
            break;
        }
        if(d instanceof Date && !isNaN(d))
            return d;
        else {
            s = str.split("-");
            d = new Date(Date.parse(s[0]+ "-" + s[1] + "-" +s[2]));
            if(d instanceof Date && !isNaN(d))
            return d;
        }
    }
    function dateToString(d){

        switch(prisma.dateFormat){
            case "dd/MM/yyyy":
                return (d.getDate()>9?d.getDate():'0'+d.getDate())+"/"+(d.getMonth()+1>9?d.getMonth()+1:'0'+(d.getMonth()+1))+"/"+d.getFullYear();
            case "MM/dd/yyyy":
                return (d.getMonth()+1>9?d.getMonth()+1:'0'+(d.getMonth()+1))+"/"+(d.getDate()>9?d.getDate():'0'+d.getDate())+"/"+d.getFullYear();
            case "yyyy-MM-dd":
                return (d.getDate()>9?d.getDate():'0'+d.getDate())+"-"+(d.getMonth()+1>9?d.getMonth()+1:'0'+(d.getMonth()+1))+"-"+d.getFullYear();
        }

    }
    function valueToNumber(value) {
        try {
            return Number(''+value);
        }catch(err){
            return Number.NaN;
        }
    }

    function valueToString (value){
        if(value !== null){    // typeof null == "object" :P
            switch (typeof value){
                case "number":
                    return ''+value;
                case "boolean":
                    return ''+value;
                case "object":
                    if(value.getFullYear){
                        return dateToFixedStrFormat(value);
                    }else
                        return "";

                case "string":
                    return value;
                default:
                    return "";
            }
        }else
            return "";
    };

    function valueToBoolean(value) {
        switch (typeof value) {
            case "number":
                return value !== 0;
            case "string":
                return value == "true" || value == "1" ? true : false;
            case "boolean":
                return value;
            default:
                return false;
        }
    };
    function cloneObject(object){
        return JSON.parse(JSON.stringify(object));
    }
    prisma.valueToString = valueToString;
    prisma.valueToBoolean = valueToBoolean;
    prisma.valueToNumber = valueToNumber;
    prisma.decodeBase64 = decodeBase64;
    prisma.setEvent = setEvent;
    prisma.trim = trim;
    prisma.hasClass = hasClass;
    prisma.addClass = addClass;
    prisma.removeClass = removeClass;
    prisma.linkify = linkify;
    prisma.dateToFixedStrFormat = dateToFixedStrFormat;
    prisma.cloneObject = cloneObject;
    prisma.dateToString = dateToString;
    prisma.stringToDate = stringToDate;
    prisma.version = "v9.2.398";

    function viewportWidth () {
      if (window.innerWidth) return window.innerWidth;
      var doc = document,
      html = doc && doc.documentElement,
      body = doc && (doc.body || doc.getElementsByTagName("body")[0]);
      var getWidth = function (elm) {
        if (!elm) return 0;
        var setOverflow = function (style, value) {
            var oldValue = style.overflow;
            style.overflow = value;
            return oldValue || "";
        },
        style = elm.style, oldValue = setOverflow(style, "hidden"), width = elm.clientWidth || 0;
        setOverflow(style, oldValue);
        return width;
      };
      return Math.max(
        getWidth(html),
        getWidth(body)
      );

    }
    prisma.viewportWidth = viewportWidth;
    function getPageTitle() {
      var titleNode;
      try {
        titleNode = window.top.document.getElementsByTagName("title")[0];
      } catch(e) {
        titleNode = window.document.getElementsByTagName("title")[0];
      }
      return (titleNode ? titleNode.innerHTML : "");
    }
    function getPageLocation() {
      var location;
      try {
        location = window.top.location.href;
      } catch (e) {
        location = window.location.href;
      }
      return location || "";
    }

    function getBrowser() {
        var browserName = "", fullVersion = "";
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset+6);
            if ((verOffset=nAgt.indexOf("Version"))!=-1)
                fullVersion = nAgt.substring(verOffset+8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset+5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset+7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset+7);
            if ((verOffset=nAgt.indexOf("Version"))!=-1)
                fullVersion = nAgt.substring(verOffset+8);
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset+8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
            browserName = nAgt.substring(nameOffset,verOffset);
            fullVersion = nAgt.substring(verOffset+1);
            if (browserName.toLowerCase()==browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        return {"name": browserName, "version": fullVersion};
    }
    prisma.getBrowser = getBrowser;
    function inherit (objSubClass, objSuperClass){
      // Duplicate prototype of superclass
      var Inheritance = function() {};
      Inheritance.prototype = objSuperClass.prototype;
      objSubClass.prototype = new Inheritance();
      // Fix constructor property to point to the self constructor because it is
      // pointing to the nested Inheritance function
      objSubClass.prototype.constructor = objSubClass;
      // Reference to constructor of superclass to be able to invoke it
      objSubClass.SUPERconstructor = objSuperClass;
      // Reference to prototype of superclass to be able to invoke its methods
      objSubClass.SUPERclass = objSuperClass.prototype;
    }

    prisma.inherit = inherit;

    function PopUp(triggeringParent, width, height, adaptToContent, context) {
        this.width = width;
        this.height = height;
        this.adaptToContent = adaptToContent;
        this.dom = this.createOverlay();
        this.triggeringParent = triggeringParent;
    }

    PopUp.prototype.getContentId = function() {
        return "prisma_popup_content";
    };

    PopUp.prototype.getContentClass = function() {
        return "popup-modal";
    };

    PopUp.prototype.createOverlay = function() {
        var overlayElement = document.getElementById("prisma_overlay_popup");
        if (overlayElement) {
            overlayElement.parentNode.removeChild(overlayElement);
        }
        overlayElement = document.createElement("div");
        overlayElement.id = "prisma_overlay_popup";
        overlayElement.className = this.adaptToContent ? "prisma_adaptive" : "";
        overlayElement.style.display = "none";

        var closeDiv = document.createElement("div");
        closeDiv.id = "";
        closeDiv.className = "popup-close";
        this.closeDiv = closeDiv;

        var contentDiv = document.createElement("div");
        contentDiv.id = this.getContentId();
        contentDiv.className = this.getContentClass();

        this.contentDiv = contentDiv;

        var wrapperDiv = document.createElement("div");
        wrapperDiv.id = "popup-wrapper";
        wrapperDiv.className = "popup-wrapper";
        wrapperDiv.appendChild(contentDiv);
        wrapperDiv.appendChild(closeDiv);
        this.wrapperDiv = wrapperDiv;

        var alignmentDiv = document.createElement("div");
        alignmentDiv.className = "popup-alignment";
        alignmentDiv.appendChild(wrapperDiv);

        overlayElement.appendChild(alignmentDiv);

        var body = document.getElementsByTagName("body")[0];
        body.appendChild(overlayElement);
        return overlayElement;
    };

    PopUp.prototype.waitForContentLoaded = function (cb) {

        if(this.waitForContent !== false){
            var eventHandler = function (event) {
                if (event.data && event.data.eventType == "viewLoadedEx") {
                    removeEventListener("message", eventHandler)
                    cb(event.data);
                }
            }
            window.addEventListener("message", eventHandler)
        }else {
            cb({});
        }
    };

    PopUp.prototype.createCloseHandlers = function () {
        var self = this;
        if (self.hideClose != true) {
            var onlyClose = document.createElement("a");
            onlyClose.className = "close";
            onlyClose.setAttribute("href", "#");
            setEvent(onlyClose, "click",
                function (e) {
                    self.hide();
                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                });
            this.closeDiv.appendChild(onlyClose);
        }
        if (this.dismissFn) {
            self.waitForContentLoaded(function (data) {
                if (!data.hasDismissActionSupport) {
                    var notInterested = document.createElement("a");
                    notInterested.className = "prisma-dismiss-component";
                    notInterested.id = "prisma-dismiss";
                    notInterested.setAttribute("href", "#");
                    setEvent(notInterested, "click",
                        function (e) {
                            self.dismissFn();
                            self.hide();
                            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                        });
                    self.closeDiv.appendChild(notInterested);
                    notInterested.innerHTML = self._T(":funnel-navigation", ":dismiss");
                }
            })
        }
    };

    PopUp.prototype.show = function() {
      if (this.dom) {
            this.createCloseHandlers();
            this.dom.style.display = "block";
            this.wrapperDiv.style.position = "absolute";
            this.wrapperDiv.style.left = "50%";
            this.wrapperDiv.style.top = "50%";
            this.wrapperDiv.style.width = this.width + "px";
            this.wrapperDiv.style.maxWidth = this.width + "px";
            this.wrapperDiv.style.height = "100%";
            this.wrapperDiv.style.maxHeight = this.height + (this.dismissFn ? 30 : 0) + "px";
            this.wrapperDiv.style["margin-left"] = "-" + this.width/2 + "px";
            this.wrapperDiv.style["margin-top"] = "-" + this.height/2 + "px";
        }
    };

    PopUp.prototype.hide = function() {
        if (this.dom) {
            this.dom.style.display = "none";
        }
    };

    PopUp.prototype.setDismissFn = function(f) {
        this.dismissFn = f;
    };

    PopUp.prototype._T = function(sectionName, key) {
        if (this.triggeringParent && this.triggeringParent.translations){
            var campaignTranslation = prisma._T(sectionName, key, this.triggeringParent.translations);
            if (campaignTranslation != ""){
                return campaignTranslation;
            } else {
                // if no campaign override is found, use the default
                return prisma._T(sectionName, key);
            }
        }
        return prisma._T(sectionName, key);
    };

    function IFramePopup (def) {
        IFramePopup.SUPERconstructor.call(this, def);
    }
    inherit(IFramePopup, PopUp);

    IFramePopup.prototype.show = function(url, width, height, dismissFn, hideClose) {
       this.dismissFn = dismissFn;
       this.hideClose = hideClose;
       this.width = width;
       this.height = height;
       var iframeNode = document.createElement("iframe");
       iframeNode.setAttribute("src", url);
       iframeNode.style.width = width + "px";
       iframeNode.style.height = height + "px";
       iframeNode.style.border = "0px";
       this.contentDiv.appendChild(iframeNode);
       IFramePopup.SUPERclass.show.call(this);
    };

    IFramePopup.prototype.getContentClass = function() {
        return "automatic-modal";
    };

    function View(def, funnel) {
        var self = this;
        this.stepData = def;
        this.funnel = funnel;
        var cfg = def.at(jsedn.kw(":config"));
        var stepConfig = cfg.at(jsedn.kw(":funnel-step-s12n/configuration"));
        var content = this.createContent((typeof stepConfig == "string" ? jsedn.parse(stepConfig) : stepConfig).jsEncode());
        if (content) { //Redirect plugin has no visible content
            this.contentDiv = content;
            if (!funnel.contentId) {
                this.dom = this.createOverlay();
            } else {
                this.dom = document.getElementById(funnel.contentId);
            }
            maxSteps = def.exists(jsedn.kw(":max-steps")) ?
                def.at(jsedn.kw(":max-steps")) : 0;
            remainingSteps = def.exists(jsedn.kw(":remaining-steps")) ?
                def.at(jsedn.kw(":remaining-steps")) : 0;
            this.dom.firstElementChild.innerHTML = "";
            this.dom.firstElementChild.appendChild(content);
            if (!this.hasActionBarSupport()) {

                this.actions = cfg.at(jsedn.kw(":funnel-step-s12n/actions"));
                this.dom.firstElementChild.appendChild(this.createActions(this.actions, remainingSteps));

              if (funnel.funnel && funnel.funnel.allowDismiss && !self.isRunningEmbedded() && !funnel.funnel.insidePopup) {
                    var dismissElement = document.createElement("a");
                    dismissElement.className = "prisma-dismiss-component";
                    dismissElement.setAttribute("href", "#");
                    dismissElement.innerHTML = self._T(":funnel-navigation", ":dismiss");
                    setEvent(dismissElement, "click",
                        function (e) {
                            self.funnel.dismiss();
                            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                        });
                    this.dom.firstElementChild.appendChild(dismissElement);
                }
            }

            if(funnel.funnel && funnel.funnel.showProgressBar) {
                this.addProgressbar(this.dom.firstElementChild, maxSteps, remainingSteps);
            }
        }
    }

    View.prototype.isRunningEmbedded = function() {
        return this.funnel.funnel && this.funnel.funnel.isEmbedded;
    }

    View.prototype.hasActionBarSupport = function() {
        return false;
    }
    View.prototype.hasDismissActionSupport = function () {
        return false;
    }
    View.prototype.setActionBarData = function (actions,showProgressBar,maxSteps,remainingSteps){

    }
    View.prototype.createOverlay = function() {
        var overlayElement = document.getElementById("prisma_overlay");
        if (!overlayElement) {
            overlayElement = document.createElement("div");
            overlayElement.id = "prisma_overlay";
            overlayElement.className = "prisma_overlay";
            overlayElement.style.display = "none";
            var contentDiv = document.createElement("div");
            contentDiv.id = "prisma_content";

            var  contentSizeClass = "pm-md"

            switch(this.funnel.funnel.popupSize.name){
                case ":small":
                    contentSizeClass = "pm-sm";
                break;
                case ":medium":
                    contentSizeClass = "pm-md";
                break;
                case ":large":
                     contentSizeClass = "pm-lg";
                break;
                case ":fullscreen":
                     contentSizeClass = "pm-fullscreen";
                break;

            }

            contentDiv.className = "funnel-modal " + contentSizeClass + " pm-center";
            overlayElement.appendChild(contentDiv);

            var body = document.getElementsByTagName("body")[0];
            body.appendChild(overlayElement);
        }
        return overlayElement;
    };

    View.prototype.createContent = function(definition){

    };

    View.prototype.wasRedirected = function(){
        return false;
    };

    View.prototype.getActionClass = function (action) {
        var name = action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString();
        switch (name) {
          case ":funnel-step-s12n-action-type/confirm":
          case ":funnel-step-s12n-action-type/unsubscribe":
          case ":funnel-step-s12n-action-type/update-communication-preferences":
          case ":funnel-step-s12n-action-type/next":
          case ":funnel-step-s12n-action-type/submit":
          case ":funnel-step-s12n-action-type/close":
             return "btn btn-primary";
          case ":funnel-step-s12n-action-type/ok":
          case ":funnel-step-s12n-action-type/cancel":
             return "btn btn-default";
          default:
             return "secondary";
        }
    };
    View.prototype.getActionName = function(action) {
        var self = this;
        var name = action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString();
        switch (name) {
            case ":funnel-step-s12n-action-type/next":
                return self._T(":funnel-navigation", ":next-button");
            case ":funnel-step-s12n-action-type/confirm":
                return self._T(":funnel-navigation", ":confirm-button");
            case ":funnel-step-s12n-action-type/submit":
                return self._T(":funnel-navigation", ":submit-button");
            case ":funnel-step-s12n-action-type/cancel":
                return self._T(":funnel-navigation", ":cancel-button");
            case ":funnel-step-s12n-action-type/ok":
                return self._T(":funnel-navigation", ":close-button");
            case ":funnel-step-s12n-action-type/close":
                if (this.funnel.showInModal()) {
                    return self._T(":funnel-navigation", ":close-button");
                } else {
                    return self._T(":funnel-navigation", ":ok-button");
                }
            case ":funnel-step-s12n-action-type/unsubscribe":
                  var section = "";
                  switch(self.funnel.unsubscriptionType){
                    case "campaign":
                      section = ":unsubscribe-campaign";
                      break;
                    case "everything":
                      section = ":unsubscribe-everything";
                      break;
                    default:
                      section = ":unsubscribe-category-group"
                  }
                return self._T(section, ":submit-button");
            case ":funnel-step-s12n-action-type/update-communication-preferences":
                return self._T(":communication-preferences", ":submit-button");
        }
    };

    View.prototype.isActionVisible = function(action){
       var name = action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString();
        switch (name) {
           case ":funnel-step-s12n-action-type/dismiss":
             return this.funnel.funnel.allowDismiss;

           case ":funnel-step-s12n-action-type/previous":
             return this.funnel.currentPosition > 0;

           case ":funnel-step-s12n-action-type/next":
           case ":funnel-step-s12n-action-type/unsubscribe":
           case ":funnel-step-s12n-action-type/ok":
           case ":funnel-step-s12n-action-type/submit":
            return true;
           case ":funnel-step-s12n-action-type/close":
          return true;//this.funnel.showInModal();
           case ":funnel-step-s12n-action-type/cancel":
            return this.funnel.showInModal() || this.funnel.returnHref;
           default:
             return true;
        }
    };
    View.prototype.isActionEnabled = function (action) {
        if(this.getWaitState())
            return false;
        var name = action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString();
        switch (name) {
            case ":funnel-step-s12n-action-type/next":
                return true;
            default:
                return true;
        }
    };

    View.prototype.addProgressbar = function(container, max_steps, remaining_steps) {
        if (max_steps) {
            var pgbar = document.createElement("div");
            pgbar.id = "prisma_progress";
            pgbar.className = "prisma-progress-component";
            var pgind = document.createElement("div");
            pgind.className = "prisma-progress-component-indicator";
            pgind.style.width = ((max_steps - remaining_steps) / max_steps) * 100 + "%";
            pgbar.appendChild(pgind);
            var pgpor = document.createElement("span");
            pgpor.innerHTML = Math.round(((max_steps - remaining_steps) / max_steps) * 100) + "%";
            pgind.appendChild(pgpor);
            container.appendChild(pgbar);
        }
    };

    View.prototype.createActions = function(actions, remaining_steps) {
        var actionsDiv = document.createElement("div");
        if (actions.val.length == 0 || this.isRunningEmbedded()) {
            return actionsDiv;
        }
        var self = this;
        self.remaining_steps = remaining_steps;
        actionsDiv.id = "prisma_actions";
        actionsDiv.className = "prisma-actions-component";
        var actionsContent = document.createElement("div");
        actionsContent.className="prisma-actions-component-content";
        for(var i = 0; i < actions.val.length; i++) {
            var a = actions.val[i];
            if(this.isActionVisible(a)){
              var button = document.createElement("button");
              button.innerHTML = this.getActionName(a);
              button.className = this.getActionClass(a);
              button.disabled = !this.isActionEnabled(a);
              button.onclick = (function(action,button) {
                  var enableButtons = function (enabled){
                    for (var i = 0;i< actions.val.length;i++){
                        if (actions.val[i].button){
                            if(!enabled){
                                actions.val[i].button.setAttribute("disabled", "disabled");
                            }else{
                                actions.val[i].button.removeAttribute("disabled");
                            }

                        }
                    }
                  }
                  return function() {
                      enableButtons(false)
                     self.funnel.onAction(action,function(status){
                        enableButtons(true);
                     });
                  };})(a,button);
              actionsContent.appendChild(button);
              a.button = button;
            }

        }
        actionsDiv.appendChild(actionsContent);
        return actionsDiv;
    };

    View.prototype.removeActions = function() {
        var actionsSection = document.getElementById("prisma_actions");
        if (actionsSection) {
            actionsSection.parentElement.removeChild(actionsSection);
        }
    };

    View.prototype._T = function(sectionName, key) {
        if (this.funnel && this.funnel.triggeringParent && this.funnel.triggeringParent.translations){
            var campaignTranslation = prisma._T(sectionName, key, this.funnel.triggeringParent.translations);
            if (campaignTranslation != ""){
                return campaignTranslation;
            } else {
                // if no campaign override is found, use the default
                return prisma._T(sectionName, key);
            }
        }
        return prisma._T(sectionName, key);
    };

    View.prototype.show = function() {
      if (this.dom) {
        this.dom.style.display = "block";
      }
    };

    View.prototype.hide = function() {
        if (this.dom) {
            this.dom.firstElementChild.innerHTML = "";
            this.dom.style.display = "none";
        }
    };
    View.prototype.showWaitIndicator = function (show){
        if (this.contentDiv) {
            if (show && !this.waitNode) {
                var waitNode = document.createElement("div")
                waitNode.className = "loading-full-width white"
                waitNode.innerHTML = "<div class=\"loading-content\"><div class=\"loading-dots\"></div></div>";
                this.contentDiv.appendChild(waitNode);
                this.waitNode = waitNode;
            } else if (this.waitNode) {
                try {
                    this.contentDiv.removeChild(this.waitNode);
                } catch (err) { /* avoid error when someone replaced the content view */ }
                this.waitNode = null;
            }
        }

    }
    View.prototype.setWaitState = function (state) {
        this.waitState = state;
        this.showWaitIndicator(state);
    }
    View.prototype.getWaitState = function () {
        var wfc = false;
        if (this.stepData.exists(jsedn.kw(":funnel-status")) &&
            this.stepData.at(jsedn.kw(":funnel-status")).toString() == ":wait-for-completion") {
            wfc = true;
        }
        return this.waitState || wfc;
    }

    View.prototype.retrieveData = function(cb){
        cb(true);
    };
    View.prototype.handleStepErrors = function (errors){
        if(errors[":funnel-error"]){
            if (":out-of-sync" == errors[":funnel-error"]){
                alert(this._T(':funnel-navigation', ':errors-failure-sending-data') + " [OS]");
            }else if (":missing-step" == errors[":funnel-error"]) {
                alert(this._T(':funnel-navigation', ':errors-failure-sending-data') + " [MS]");
            }
        }
    }
    function DismissalView (def,funnel) {
        DismissalView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(DismissalView, View);

    DismissalView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":dismissed-campaign", ":title");
        var message = this._T(":dismissed-campaign", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title,message);
        return body;
    };

    function startFunnelView (def,funnel) {
        startFunnelView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(startFunnelView, View);

    startFunnelView.prototype.hasActionBarSupport = function() {
        return true;
    }

    startFunnelView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        return body;
    };

    function ConversionView (def,funnel) {
        ConversionView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(ConversionView, View);

    ConversionView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":conversion", ":title");
        var message = this._T(":conversion", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title,message);
        return body;
     };

    function FinishedView (def,funnel) {
        FinishedView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(FinishedView, View);

    FinishedView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":finished", ":title");
        var message = this._T(":finished", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title,message);
        return body;
     };

    function AsyncFunnelStepView (def,funnel) {
        AsyncFunnelStepView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(AsyncFunnelStepView, View);

    AsyncFunnelStepView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":async-step", ":title");
        var message = this._T(":async-step", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title,message);
        return body;
    };

    function RedirectedView (def,funnel, url) {
        this.url = url;
        RedirectedView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(RedirectedView, View);

    RedirectedView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":redirect-fallback", ":title");
        var message = this._T(":redirect-fallback", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title, prisma.format(message, this.url));
        return body;
     };


    function NotOnScheduleView (def,funnel) {
        NotOnScheduleView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(NotOnScheduleView, View);

    NotOnScheduleView.prototype.createContent = function(definition) {
        var body = document.createElement("div");
        body.className = "message-display";
        var title = this._T(":ended-campaign", ":title");
        var message = this._T(":ended-campaign", ":body");
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>",title,message);
        return body;
     };

    function UnsubscribeViewConfirm(def,funnel) {
        UnsubscribeViewConfirm.SUPERconstructor.call(this, def, funnel);
    }
    inherit(UnsubscribeViewConfirm, View);

    UnsubscribeViewConfirm.prototype.createContent = function(definition) {
        var self = this;
        var body = document.createElement("div");
        body.className = "message-display";
        switch (self.funnel.unsubscriptionType) {
            case "campaign":
                var title = self._T(":unsubscribe-campaign-confirmation", ":title");
                var message = self._T(":unsubscribe-campaign-confirmation", ":body");
                break;
            case "category":
                var title = self._T(":unsubscribe-category-group-confirmation", ":title");
                var message = prisma.format(self._T(":unsubscribe-category-group-confirmation", ":body"), self.funnel.categoryName);
                break;
            case "group":
                var title = self._T(":unsubscribe-category-group-confirmation", ":title");
                var message = prisma.format(self._T(":unsubscribe-category-group-confirmation", ":body"), self.funnel.groupName);
                break;
            case "category-and-group":
                var title = self._T(":unsubscribe-category-group-confirmation", ":title");
                var message = prisma.format(self._T(":unsubscribe-category-group-confirmation", ":body"), self.funnel.categoryName + " - " + self.funnel.groupName);
                break;
            default:
                var title = self._T(":unsubscribe-everything-confirmation", ":title");
                var message = self._T(":unsubscribe-everything-confirmation", ":body");
                break;
        }
        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p></div>", title, message);
        return body;
    };

    function UnsubscribeView (def,funnel) {
        UnsubscribeView.SUPERconstructor.call(this, def, funnel);
    }
    inherit(UnsubscribeView, View);

    UnsubscribeView.prototype.createContent = function (definition) {

        otherInputVisibility = function (action) {
            var otherRB = document.getElementById("prisma_field_unsubscribeReason_other");
            var otherTextInput = document.getElementById('prisma_field_unsubscribeReason_other_input');
            // 'Other' option could be removed by the user so make sure the options are available.
            if (otherRB && otherTextInput) {
                if (otherRB.checked) {
                    otherTextInput.style.display = 'block';
                } else {
                    otherTextInput.style.display = 'none';
                }
            }
        }

        var body = document.createElement("div");
        body.className = "message-display";
        switch (this.funnel.unsubscriptionType) {
            case "campaign":
                var title = this._T(":unsubscribe-campaign", ":title");
                var message = this._T(":unsubscribe-campaign", ":subtitle");
                var submessage = this._T(":unsubscribe-campaign", ":body");
                break;
            case "category":
                var title = this._T(":unsubscribe-category-group", ":title");
                var message = prisma.format(this._T(":unsubscribe-category-group", ":subtitle"), this.funnel.categoryName);
                var submessage = this._T(":unsubscribe-campaign", ":body");
                break;
            case "group":
                var title = this._T(":unsubscribe-category-group", ":title");
                var message = prisma.format(this._T(":unsubscribe-category-group", ":subtitle"), this.funnel.groupName);
                var submessage = this._T(":unsubscribe-campaign", ":body");
                break;
            case "category-and-group":
                var title = this._T(":unsubscribe-category-group", ":title");
                var message = prisma.format(this._T(":unsubscribe-category-group", ":subtitle"), this.funnel.categoryName + " - " + this.funnel.groupName);
                var submessage = this._T(":unsubscribe-campaign", ":body");
                break;
            default:
                var title = this._T(":unsubscribe-everything", ":title");
                var message = this._T(":unsubscribe-everything", ":subtitle");
                var submessage = this._T(":unsubscribe-everything", ":body");
        }

        body.innerHTML = prisma.format("<h1 class='prisma-title-component'>{0}</h1><div class='prisma-text-component'><p>{1}</p><p>{2}</p></div>", title, message, submessage);
        //create all options for unsubscription with a radio button
        var fieldName = "unsubscribeReason";
        var formElement = document.createElement("form");
        formElement.className = "prisma_form form-horizontal prisma-datacapture-component clearfix";
        var clearRow = document.createElement("div");
        clearRow.className = "";
        formElement.appendChild(clearRow);

        var formDiv = document.createElement("div");
        formDiv.className = "form-group";
        clearRow.appendChild(formDiv);
        var rowDiv = document.createElement("div");
        rowDiv.className = "prisma-radiobutton-component";
        formDiv.appendChild(rowDiv);

        var radioUL = document.createElement("ul");
        radioUL.className = "list-unstyled";
        radioUL.style.marginLeft = "30px";
        var options = this.funnel.reasonsList;
        for (i = 0; i < options.length; ++i) {
            var radioLI = document.createElement("li");
            radioLI.className = "radio";
            var opt = options[i][0];
            var optType = options[i][1];
            var rb = document.createElement("input");
            rb.type = "radio";
            rb.id = "prisma_field_" + fieldName + "_" + i;
            rb.name = "prisma_field_" + fieldName;
            var s = document.createElement("label");
            s.className = "control-label";
            var span = document.createElement('span');
            span.innerHTML = opt;
            s.setAttribute("for", "prisma_field_" + fieldName + "_" + +i);
            s.appendChild(rb);
            s.appendChild(span);
            radioLI.appendChild(s);
            radioUL.appendChild(radioLI);
            rb.setAttribute("onclick", "javascript:otherInputVisibility()");
            if (optType == ":option-other") {
                var otherTextInput = document.createElement('textarea');
                otherTextInput.setAttribute("type", "text");
                otherTextInput.className = "form-control";
                otherTextInput.id = "prisma_field_unsubscribeReason_other_input";
                otherTextInput.style.display = 'none';
                s.appendChild(otherTextInput);
                rb.id = "prisma_field_" + fieldName + "_other";
                s.setAttribute("for", "prisma_field_" + fieldName + "_other");
            }
        }
        rowDiv.appendChild(radioUL);
        body.appendChild(formElement);
        return body;
    };

  function Unsubscription(handler, triggeringParent, campaignId, campaignName, categoryName, groupName, unsubscriptionType, trailId, style, translationsTypes) {
      this.handler = handler;
      this.trailId = trailId;
      this.currentView = undefined;
      this.campaignId = campaignId;
      this.triggeringParent = triggeringParent;
      this.campaignName = campaignName;
      this.categoryName = categoryName;
      this.groupName = groupName;
      this.unsubscriptionType = unsubscriptionType;
      this.style = decodeBase64(style);
      this.translationsTypes = translationsTypes;

      this.reasonsList = [];
      var section = "";
      switch(this.unsubscriptionType){
        case "campaign":
          section = ":unsubscribe-campaign";
          break;
        case "everything":
          section = ":unsubscribe-everything";
          break;
        default:
          section = ":unsubscribe-category-group"
      }
      var translations = triggeringParent.translations[section];
      var types = translationsTypes.translationsTypes[section];
      for (var field in translations){
          if (translations.hasOwnProperty(field) && field.includes("option", 0)){
              this.reasonsList.push([translations[field], types[field]]);
          }
      }
      this.reasonsList.sort(function (a, b) {
          if (a[1] == ":option-other") {
              return 1;
          }
          if (b[1] == ":option-other" || a[0].localeCompare(b[0]) < 0) {
              return -1;
          }
          if (a[0].localeCompare(b[0]) > 0) {
              return 1;
          }
          return 0;
      });
  }

    Unsubscription.prototype.onAction = function(action,cb) {
      var self = this;
      var url = "/api/campaigns/unsubscribe";
      var tData = [];
      var otherInput = document.getElementById("prisma_field_unsubscribeReason_other_input")
      var otherReason = (otherInput && trim(otherInput.value)) || "";
      var reasons = document.getElementsByName("prisma_field_unsubscribeReason");
      var reason = [];
      for (var i = 0; i < reasons.length; i++) {
        if (reasons[i].checked) {
          reason = self.reasonsList[i];
          break;
        }
      }
      var map = [new jsedn.kw(":campaign-id"), self.campaignId,
                 new jsedn.kw(":reason"), reason[0],
                 new jsedn.kw(":reason-type"), reason[1],
                 new jsedn.kw(":trail-id"), self.trailId];
      if (otherReason != "" && reason[1] == ":option-other") {
        map = map.concat([new jsedn.kw(":other-reason"), otherReason]);
      }
      var query = new jsedn.Map(map);
      var onSuccessFn = function (response){
          //var response = jsedn.parse(response);
          self.showConfirmation();
          cb(true);
      };
      var onError = function (response){
          cb(false)
      };
      this.handler.api.post(url, query, onSuccessFn, onError);
    };

    Unsubscription.prototype.showConfirmation = function() {
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.currentView = new UnsubscribeViewConfirm(def, this);
        this.currentView.show();
    };

    Unsubscription.prototype.show = function(elementId) {
        this.contentId = elementId;
        this.createStyleNode();
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions [{:funnel-step-s12n-action/type :funnel-step-s12n-action-type/unsubscribe}]}}");
        this.currentView = new UnsubscribeView(def, this);
        this.currentView.show();
    };


    Unsubscription.prototype.createStyleNode = function() {
        var cssNode = document.createElement('style');
        cssNode.type = 'text/css';
        if (cssNode.styleSheet) {
            cssNode.styleSheet.cssText = this.style;
        }
        else {
            cssNode.appendChild(document.createTextNode(this.style));
	      }
        document.getElementsByTagName("head")[0].appendChild(cssNode);
        this.stylesNode = cssNode;
    };


    function CommunicationPreferencesView(def, funnel) {
        CommunicationPreferencesView.SUPERconstructor.call(this, def, funnel);
    }

    inherit(CommunicationPreferencesView, View);

    /**
     * @param type {string}
     * @param element {Record<':id', ':name', ':subscribed?'>}
     * @returns {HTMLDivElement}
     */
    CommunicationPreferencesView.prototype.createCheckbox = function(type, element) {
        const elementId = "prisma-checkbox-" + type + "-" + element[":id"];

        let container = document.createElement('div');
        container.className = "prisma-checkbox-component";

        let label = document.createElement('label');
        label.className = "control-label";
        label.for = elementId;

        let input = document.createElement('input');
        input.type = "checkbox";
        input.id = elementId;
        input.checked = element[":subscribed?"];
        input.className = "prisma-select-" + type;
        input.addEventListener("change", function(event) {
            element[":subscribed?"] = event.target.checked;
        });

        let span = document.createElement('span');
        span.className = "prisma-checkbox-text";
        span.appendChild(document.createTextNode(element[":name"]));

        label.appendChild(input);
        label.appendChild(span);
        container.appendChild(label);
        return container;
    };

    /**
     * Like self._t but bound to section `:communication-preferences`
     */
    CommunicationPreferencesView.prototype.__T = function (key) {
        return View.prototype._T.call(this, ":communication-preferences", key);
    };

    CommunicationPreferencesView.prototype.createContent = function (definition) {
        let self = this;

        let setElementTextById = function(id, text, prop) {
            let e = document.getElementById(id);
            if (e) {
                e[prop || 'textContent'] = text;
            }
        };

        let enableAllCheckboxes = function (type, enabled) {
            Array.from(document.getElementsByClassName("prisma-select-" + type)).forEach(function (e) {
                e.disabled = !enabled;
                e.parentElement.className = "control-label" + (enabled? "" : " disabled");
            });
            Array.from(document.getElementsByClassName("prisma-enable-disable-all")).forEach(function (e) {
                e.className = "prisma-enable-disable-all text-left" + (enabled ? "" : " text-muted");
            });
            Array.from(document.getElementsByClassName("prisma-select-unselect-all")).forEach(function (e) {
                e.disabled = !enabled;
                e.className = "prisma-select-unselect-all" + (enabled ? "" : " text-muted");
                e.style.opacity = enabled ? 1 : 0.5;
                e.style.cursor = enabled ? "pointer" : "not-allowed";
            });
        };

        setElementTextById("prisma-preferences-title", self.__T(":title"));
        setElementTextById("prisma-preferences-subtitle", self.__T(":subtitle"));
        setElementTextById("prisma-preferences-channels-title", self.__T(":channels-title"));
        setElementTextById("prisma-preferences-channels-subtitle", self.__T(":channels-subtitle"));
        setElementTextById("prisma-preferences-categories-title", self.__T(":categories-title"));
        setElementTextById("prisma-preferences-categories-subtitle", self.__T(":categories-subtitle"), 'innerHTML');
        setElementTextById("prisma-preferences-groups-title", self.__T(":groups-title"));
        setElementTextById("prisma-preferences-groups-subtitle", self.__T(":groups-subtitle"), 'innerHTML');
        setElementTextById("prisma-preferences-channel-email", self.__T(":channel-email"));
        setElementTextById("prisma-preferences-channel-sms", self.__T(":channel-sms"));
        setElementTextById("prisma-preferences-channel-pushn", self.__T(":channel-pushn"));
        setElementTextById("all-channels-disabled-msg-text", self.__T(":outbound-already-disabled"));

        Array.from(document.getElementsByClassName("prisma-select-unselect-all")).forEach(function (e) {
            if (e.id.indexOf("unselect") > 0) {
                e.textContent = self.__T(":unselect-all");
            } else {
                e.textContent = self.__T(":select-all");
            }
        });

        ["categories", "groups"].forEach(function(type) {
            let container = document.getElementById("prisma-" + type + "-container");

            let data = self.funnel[type];
            data.map(e => self.createCheckbox(type, e))
                .forEach(e => container.appendChild(e));

            [true, false].forEach(v => {
                let sa = document.getElementById("prisma-" + (v ? "" : "un") + "select-all-" + type);
                sa.addEventListener("click", function () {
                    if (!sa.disabled) {
                        let checkboxes = document.getElementsByClassName("prisma-select-" + type);
                        for (let i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = v;
                            data[i][":subscribed?"] = v;
                        }
                    }
                });
            });
        });

        const disableAllCheckbox = document.getElementById("subscribe-channel-all");
        disableAllCheckbox.addEventListener("change", function(event) {
            self.funnel.allChannelsDisabled = disableAllCheckbox.checked;
            enableAllCheckboxes("categories", !disableAllCheckbox.checked && self.funnel.disabledChannels.length < self.funnel.supportedChannels.length);
            enableAllCheckboxes("groups", !disableAllCheckbox.checked && self.funnel.disabledChannels.length < self.funnel.supportedChannels.length);
            enableAllCheckboxes("channel", !disableAllCheckbox.checked);
        });
        if (self.funnel.allChannelsDisabled) {
            disableAllCheckbox.checked = true;
            self.funnel.disabledChannels = [...self.funnel.supportedChannels];
            disableAllCheckbox.dispatchEvent(new Event('change'));
        }

        self.funnel.supportedChannels.forEach(channel => {
            let element = document.getElementById("subscribe-channel-" + channel);
            if (element) {
                element.checked = !self.funnel.disabledChannels.includes(channel);
                element.addEventListener("change", function(event) {
                    if (event.target.checked) {
                        const index = self.funnel.disabledChannels.indexOf(channel);
                        if (index >= 0) {
                            self.funnel.disabledChannels.splice(index, 1);
                        }
                        enableAllCheckboxes("categories", true);
                        enableAllCheckboxes("groups", true);
                    } else {
                        self.funnel.disabledChannels.push(channel);
                        if (self.funnel.disabledChannels.length === self.funnel.supportedChannels.length) {
                            enableAllCheckboxes("categories", false);
                            enableAllCheckboxes("groups", false);
                        }
                    }
                });
            }
        });

        if (self.funnel.hideChannels || self.funnel.supportedChannels === 0) {
            document.getElementById('prisma-preferences-channels').style.display = 'none';
        }

        if (self.funnel.hideCategories || self.funnel.categories.length === 0) {
            document.getElementById('prisma-preferences-categories').style.display = 'none';
        }

        if (self.funnel.hideGroups || self.funnel.groups.length === 0) {
            document.getElementById('prisma-preferences-groups').style.display = 'none';
        }

        return document.createElement('div'); // return a dummy element
    };

    /**
     * @param handler  {Prisma}
     * @param triggeringParent {string}
     * @param style {string}
     * @param settings {object}
     * @param campaignId {number}
     * @param customerId {string}
     * @param trailId {string}
     * @param translationsTypes {object}
     * @param categories {array}
     * @param groups {array}
     * @param disabledChannels {array}
     * @constructor
     */
    function CommunicationPreferences(handler, triggeringParent, style, settings,
                                      campaignId, customerId, trailId, translationsTypes,
                                      categories, groups, disabledChannels) {
        this.currentView = undefined;
        this.handler = handler;
        this.triggeringParent = triggeringParent;
        this.style = decodeBase64(style);
        this.settings = settings;
        this.campaignId = campaignId;
        this.customerId = customerId;
        this.trailId = trailId;
        this.translationsTypes = translationsTypes;
        this.categories = categories;
        this.groups = groups;

        this.hideChannels = this.settings[":hide-channels"];
        this.hideCategories = this.settings[":hide-categories"];
        this.hideGroups = this.settings[":hide-groups"];

        let index = disabledChannels.indexOf("all");
        this.allChannelsDisabled = index >= 0;
        if (index >= 0) {
            disabledChannels.splice(index, 1);
        }
        this.disabledChannels = disabledChannels;
        this.supportedChannels = this.hideChannels ? [] : ["email", "sms", "pushn"];
    }

    CommunicationPreferences.prototype.show = function (elementId) {
        let def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions [{:funnel-step-s12n-action/type :funnel-step-s12n-action-type/update-communication-preferences}]}}");

        let createStyleNode = function() {
            let cssNode = document.createElement("style");
            cssNode.appendChild(document.createTextNode(this.style));
            document.getElementsByTagName("head")[0].appendChild(cssNode);
            return cssNode;
        };

        this.contentId = elementId;
        this.stylesNode = createStyleNode();
        this.currentView = new CommunicationPreferencesView(def, this);
        this.currentView.show();
    };

    CommunicationPreferences.prototype.showConfirmation = function() {
        this.currentView.removeActions();

        let root = document.getElementById("root-wrapper");
        let body = document.createElement("div");
        body.className = "message-display";

        let title = document.createElement("h1");
        title.className = "prisma-title-component";
        title.appendChild(document.createTextNode(this.currentView.__T(":confirmation-title")));

        let subtitle = document.createElement("div");
        subtitle.className = "prisma-text-component";
        let text = document.createElement("p");
        text.appendChild(document.createTextNode(this.currentView.__T(":confirmation-body")));
        subtitle.appendChild(text);

        body.appendChild(title);
        body.appendChild(subtitle);
        root.replaceWith(body)
    };

    CommunicationPreferences.prototype.onAction = function(action, cb) {
        let self = this;

        if (!self.customerId) { // e.g: when previewing landing page
            cb(false);
            return;
        }

        let coerce = function(e) {
            return new jsedn.Map([jsedn.kw(":id"), e[":id"],
                                  jsedn.kw(":subscribed?"), e[":subscribed?"]]);
        };

        const url = "/api/campaigns/communication-preferences";
        const data = new jsedn.Map([
            jsedn.kw(":campaign-id"), this.campaignId,
            jsedn.kw(":customer-id"), this.customerId,
            jsedn.kw(":trail-id"), this.trailId,
            jsedn.kw(":categories"), this.categories.map(coerce),
            jsedn.kw(":groups"), this.groups.map(coerce),
            jsedn.kw(":all-channels-disabled"), this.allChannelsDisabled,
            jsedn.kw(":disabled-channels"), this.disabledChannels,
            jsedn.kw(":supported-channels"), this.supportedChannels,
        ]);

        let onSuccess = function(response) {
            self.showConfirmation();
            cb(true);
        };

        let onError = function(response) {
            cb(false);
        };

        return this.handler.api.post(url, data, onSuccess, onError);
    };

    function Funnel(triggeringParent, customerId, campaignId, handler, config, trackingToken, contentId) {
        var self = this;
        self.triggeringParent = {translations: triggeringParent.translations};
        self.handler = handler;
        self.trackingToken = trackingToken;
        self.campaignId = campaignId;
        self.customerId = customerId;
        self.placeholderId = triggeringParent.placeholderId;
        self.funnel = self.loadFunnel(config);
        self.funnel.steps = new jsedn.Vector([]);
        self.currentView = undefined;
        self.currentPosition = 0;
        self.contentId = contentId;
    }

    Funnel.prototype.showInModal = function() {
        return this.contentId == undefined ||
               (this.funnel && this.funnel.isEmbedded) ||
               (this.funnel && this.funnel.insidePopup);
    };

    Funnel.prototype.loadFunnel = function(config) {
        var funnel = {};
        funnel.steps = null;
        funnel.hasSteps = config.exists(jsedn.kw(":funnel/has-steps?")) ?
                                  config.at(jsedn.kw(":funnel/has-steps?")) : false;
        funnel.isEmbedded = config.exists(jsedn.kw(":funnel/embedded?")) ?
                                  config.at(jsedn.kw(":funnel/embedded?")) : false;
        funnel.initConverted = config.exists(jsedn.kw(":funnel/converted")) && !funnel.hasSteps ?
                                  config.at(jsedn.kw(":funnel/converted")) : false;
        funnel.style = decodeBase64(config.at(jsedn.kw(":funnel/style")));
        funnel.allowDismiss = config.exists(jsedn.kw(":funnel/allow-dismiss")) ?
                                  config.at(jsedn.kw(":funnel/allow-dismiss")) : false;
        funnel.customerValid = config.exists(jsedn.kw(":funnel/customer-valid?")) ?
                                  config.at(jsedn.kw(":funnel/customer-valid?")) : true;
        funnel.onSchedule = config.exists(jsedn.kw(":funnel/on-schedule?")) ?
                                  config.at(jsedn.kw(":funnel/on-schedule?")) : true;
        funnel.landingPage = config.exists(jsedn.kw(":funnel/landing-page")) ?
	                                config.at(jsedn.kw(":funnel/landing-page")) : null;

        funnel.alwaysUseLanding = config.exists(jsedn.kw(":funnel/always-use-landing")) ?
	                                config.at(jsedn.kw(":funnel/always-use-landing")) : null;

        funnel.popupSize = config.exists(jsedn.kw(":funnel/popup-size")) ?
	                                config.at(jsedn.kw(":funnel/popup-size")) : ":medium";


        funnel.showProgressBar = config.exists(jsedn.kw(":funnel/show-progress-bar")) ?
	                                config.at(jsedn.kw(":funnel/show-progress-bar")) : null;

        funnel.newWindow = config.exists(jsedn.kw(":funnel/new-window")) ?
	                                config.at(jsedn.kw(":funnel/new-window")) : null;
        funnel.insidePopup = config.exists(jsedn.kw(":funnel/inside-popup")) ? config.at(jsedn.kw(":funnel/inside-popup")) : false;

        return funnel;
    };

    Funnel.prototype.showDismissalView = function() {
        //create a bogus step configuration for dismissal view (no actions, no dismissal, no nothing)
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.funnel.allowDismiss = false;
        this.currentView = new DismissalView(def, this);
        this.currentView.show();
    };

    Funnel.prototype.showConversionView = function(returnHref) {
        //create a bogus step configuration for conversion view
        var def;
        if (returnHref) {
          def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions [{:funnel-step-s12n-action/type :funnel-step-s12n-action-type/ok}]}}");
        } else {
          def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        }
        this.funnel.allowDismiss = false;
        this.currentView = new ConversionView(def, this);
        this.currentView.show();
    };

    Funnel.prototype.showStartFunnelView = function(){
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.currentView = new startFunnelView(def, this);
        this.currentView.show();
        this.currentView.showWaitIndicator(true);
    };

    Funnel.prototype.showFinishedView = function(returnHref) {
        //create a bogus step configuration for conversion view (no actions, no dismissal, no nothing)
        var def;
        if (returnHref) {
          def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions [{:funnel-step-s12n-action/type :funnel-step-s12n-action-type/ok}]}}");
        } else {
          def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        }
        this.funnel.allowDismiss = false;
        this.currentView = new FinishedView(def, this);
        this.currentView.show();
    };

    Funnel.prototype.showAsyncFunnelStepView = function() {
        //create a bogus step configuration for conversion view (no actions, no dismissal, no nothing)
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.funnel.allowDismiss = false;
        this.currentView = new AsyncFunnelStepView(def, this);
        this.currentView.show();
    };

    Funnel.prototype.showRedirectedView = function(redirectedUrl) {
        //create a bogus step configuration for redirect view (no actions, no dismissal, no nothing)
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.funnel.allowDismiss = false;
        this.currentView = new RedirectedView(def, this, redirectedUrl);
        this.currentView.show();
    };

    Funnel.prototype.showNotScheduledView = function() {
        //create a bogus step configuration for conversion view (no actions, no dismissal, no nothing)
        var def = jsedn.parse("{:config {:funnel-step-s12n/configuration {} :funnel-step-s12n/actions []}}");
        this.funnel.allowDismiss = false;
        this.currentView = new NotOnScheduleView(def, this);
        this.currentView.show();
    };

    Funnel.prototype.dismiss = function() {
        var self = this;
        var onDismissFn = function (response, error) {
             if (response == undefined || response == null) {
               if (window.console) {
                   console.log("Error dismissing campaing:" + error);
               }
               return;
             }
             self.handler.reset();
             if (self.showInModal() && self.currentView) {
                 self.currentView.hide();
             }
             else if (!self.showInModal()) {
                self.showDismissalView();
             }
             self.notifyEvent("dismissed");
             if(self.fn_end) {
                self.fn_end();
             }
        }
        var trackData = this.newTrackData();
        this.handler.tracker.track("dismiss","dismiss", trackData, onDismissFn);
    };

    Funnel.prototype.newTrackData = function(){
        var trackData = {"tracking-token" : this.trackingToken};
        return trackData;
    };
    function isTimeoutError (err){
       return  err.code == -32099 && err.message && err.message.message && err.message.message.indexOf("timeout") !== -1 ||
               err.status == "timeout";
    }

    //this function has 2 hidden parameters, the 3rd is for retry purposes, the 4th is funnel source for VQs
    Funnel.prototype.nextFunnelStep = function (cb, context) {
        var self = this;
        var handleData = function (status, data) {
            if (status) {
                var retryFunnelSource = null;
                if (typeof(arguments[3]) != "undefined"){ //hidden 4th parameter
                    retryFunnelSource = arguments[3];
                }
                self.processFunnelStep(retryFunnelSource, data,
                    self.trackingToken,
                    self.campaignId,
                    context,
                    function (stepData) {
                        if (stepData.exists(jsedn.kw(":funnel-status"))){
                            if (stepData.at(jsedn.kw(":funnel-status")).toString() == ":validation-error"){
                                self.currentView.handleStepErrors(stepData.at(jsedn.kw(":errors")).jsEncode());
                                cb(false)
                            } else if (stepData.at(jsedn.kw(":funnel-status")).toString() == ":wait-for-completion") {
                                setTimeout(function () {
                                    self.nextFunnelStep(cb, context, true)
                                }, 8000);

                            } else if (stepData.at(jsedn.kw(":funnel-status")).toString() == ":error") {
                                if (window.console) {
                                    console.error("Error sending data: funnel-status = error");
                                }
                                alert(self.currentView._T(':funnel-navigation', ':errors-failure-sending-data'));
                                cb(false);
                            }
                        } else {
                            self.funnel.steps.val[self.currentPosition + 1] = stepData;
                            cb(true);
                        }
                    },
                    function (err) {
                        if(err.data && err.data.status == 400){
                            self.currentView.handleStepErrors(jsedn.parse(err.data.data).jsEncode())
                            cb(false)
                        } else
                          if (window.console) {
                               console.error("Error sending data, error:", err);
                          }
                          if (isTimeoutError(err)) {
                              alert("Server is busy, please try again in a moment");
                          }
                          else {
                              alert(self.currentView._T(':funnel-navigation', ':errors-failure-sending-data'));
                          }
                          cb(false);
                    });
            }
        }
        // Argumento oculto usado internamente cuando da timeout o el server esta esperando que el proceso termine.
        if (typeof(arguments[2]) != "undefined")
          if (arguments[2] == true) {
            handleData(true, jsedn.kw(":funnel/retry"));
          } else {
            handleData(true);
          }
        else
            this.currentView.retrieveData(function (status, data) {
                if (status) {
                    data = data || new jsedn.Map([]);
                    if (data.set instanceof Function){ /* some plugins returns array or js map */
                        var targetS12nKw = new jsedn.kw(":target-s12n");
                        if (self.currentView.stepData.exists(targetS12nKw) &&
                            self.currentView.stepData.at(targetS12nKw) !== null) {
                            data.set(targetS12nKw, self.currentView.stepData.at(targetS12nKw));
                        }
                    }
                    handleData(status, data);
                } else
                    cb(false);
            });
    };

    Funnel.prototype.showStepErrors = function(strErrors) {
        var errors = jsedn.parse(strErrors).jsEncode();
        this.currentView.handleStepErrors(errors);
    }

    Funnel.prototype.retrieveStepData = function() {
        var self = this;
        if (typeof(PrismaHost) == "undefined" || typeof(PrismaHost.hostDataHandler) == "undefined") {
            return;
        }
        if (!this.currentView) {
            PrismaHost.hostDataHandler();
            return;
        }
        this.currentView.retrieveData(function (status, data) {
                if (status) {
                    data = data || new jsedn.Map([]);
                    if (data.set instanceof Function){
                        var targetS12nKw = new jsedn.kw(":target-s12n");
                        if (self.currentView.stepData.exists(targetS12nKw) &&
                            self.currentView.stepData.at(targetS12nKw) !== null) {
                            data.set(targetS12nKw, self.currentView.stepData.at(targetS12nKw));
                        }
                    }
                    PrismaHost.hostDataHandler(jsedn.encode(data));
                }
        });
    }

    Funnel.prototype.loadCurrentView = function() {
        var self = this;
        var currentStep = self.funnel.steps.at(self.currentPosition);

        if (self.showInModal() && self.currentView) {
            self.currentView.hide();
        }
        if(currentStep && currentStep.exists(jsedn.kw(":funnel-step/type"))){
            self.loadView();
            //TODO: if loaded view was a redirect what should I do here in the non
            //modal case? since the previous funnel step is still on screen.
            //Now I'm shoing the thank you screen, not clear at all this is right
            if (!self.showInModal() && self.currentView.wasRedirected()) {
                self.showRedirectedView(self.currentView.redirectedUrl);
            }
        }
        if (currentStep.exists(jsedn.kw(":converted?")) && currentStep.at(jsedn.kw(":converted?"))) {
            self.notifyEvent("confirmed");
            self.handler.reset();
            if (!self.showInModal() && self.currentView) {
                self.showConversionView(this.returnHref);
            }
            if(self.fn_end)
                self.fn_end();
        }
        if (currentStep.exists(jsedn.kw(":converted?")) && !currentStep.at(jsedn.kw(":converted?"))
            && currentStep.exists(jsedn.kw(":final-step?")) && currentStep.at(jsedn.kw(":final-step?"))) {
            self.notifyEvent("confirmed");
            self.handler.reset();
            if (!self.showInModal() && self.currentView) {
                self.showFinishedView(this.returnHref);
            }
            if(self.fn_end)
                self.fn_end();
        }
        if (currentStep.exists(jsedn.kw(":async-funnel-step?")) && currentStep.at(jsedn.kw(":async-funnel-step?"))) {
            if (!self.showInModal() && self.currentView) {
                self.showAsyncFunnelStepView();
            }
        }
    };


    Funnel.prototype.notifyEvent = function (message) {
        window.top.postMessage(message, "*");
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(message);
        }
    }

    Funnel.prototype.onAction = function (action, cb) {
        var self = this;
        if (typeof (PrismaHost) != "undefined" && (typeof PrismaHost.hostOnAction == "function")) {
            PrismaHost.hostOnAction(action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString());
            return;
        }
        switch (action.at(jsedn.kw(":funnel-step-s12n-action/type")).toString()) {

            case ":funnel-step-s12n-action-type/dismiss":
                if (self.funnel.insidePopup) {
                    self.notifyEvent({ "eventType": "dismiss" });
                } else {
                    self.dismiss();
                }
                cb(true);
                break;
            case ":funnel-step-s12n-action-type/previous":
                self.currentPosition = self.currentPosition - 1;
                self.loadCurrentView();
                cb(true);
                break;
            case ":funnel-step-s12n-action-type/next":
            case ":funnel-step-s12n-action-type/submit":
            case ":funnel-step-s12n-action-type/close":
            case ":funnel-step-s12n-action-type/confirm":
                self.currentView.setWaitState(true);
                var context = new jsedn.Map();
                var actionId = "";
                if (action.exists(new jsedn.kw(":funnel-step-s12n-action/id"))) {
                    actionId = action.at(jsedn.kw(":funnel-step-s12n-action/id"));
                }
                context.set(new jsedn.kw(":funnel-action/id"), actionId);
                self.nextFunnelStep(
                    function (status) {
                        self.currentView.setWaitState(false);
                        if (status) {
                            self.currentPosition = self.currentPosition + 1;
                            self.loadCurrentView();
                            cb(true);
                        } else {
                            self.notifyEvent("confirmed");
                            cb(false)
                        }
                    }, context);
                break;
          case ":funnel-step-s12n-action-type/ok":
          case ":funnel-step-s12n-action-type/cancel":
                self.notifyEvent("canceled");
                this.currentView.hide();
                this.removeStyleNode();
                if (this.fn_end)
                    this.fn_end();
                if(self.funnel.insidePopup){
                    self.notifyEvent("cancelled");
                }
                cb(true);
                if (this.returnHref) {
                    window.top.location.href = this.returnHref;
                }
                break;
        }
    };

    Funnel.prototype.start = function(fn_end, source,context) {
        this.fn_end = fn_end;
        if (this.handler.alwaysUseLanding || (this.funnel.landingPage && this.funnel.alwaysUseLanding)) {
            this.openFunnelLanding(source,context);
        }
        else {
            this.startWizard(source,context);
        }
    };

    Funnel.prototype.runInSandbox = function(hash, host) {
        if (hash != null) {
           this.sandboxHash = hash;
           this.sandboxHost = host;
        }
    }

    Funnel.prototype.startWizard = function (source, context) {
        var self = this;
        if (self.funnel.initConverted) {
           self.showConversionView();
           return;
        } else
            // show startFunnelView to show the initial wait
            self.showStartFunnelView();
        self.createStyleNode();

        // Here we will try to get the first step in the funnel.
        // That's why we don't have stepData or funnelNodeId.
        this.processFunnelStep(
            source,
            null,
            self.trackingToken,
            self.campaignId,
            context,
            function (response) {
                self.funnel.steps = new jsedn.Vector([response]);
                self.currentPosition = 0;
                self.loadCurrentView();
                if (self.currentView.getWaitState()) {
                    self.currentView.showWaitIndicator(true)
                    setTimeout(function () {
                        self.nextFunnelStep(function (status) {
                            self.currentView.setWaitState(false);
                            if (status) {
                                self.currentPosition = self.currentPosition + 1;
                                self.loadCurrentView();
                            }
                        }, context, true)
                    }, 10);
                }
            },
            function (err) {
                if (isTimeoutError(err)) {
                    alert("Server is busy, please try again later...");
                } else {
                    if (window.console) {
                        console.error("There was an error loading the page.");
                    }
                    alert("There was an error loading the page.");
                }
            });
    };

    Funnel.prototype.createStyleNode = function() {
        var cssNode = document.createElement('style');
        cssNode.type = 'text/css';
        if (cssNode.styleSheet) {
            cssNode.styleSheet.cssText = this.funnel.style;
        }
        else {
            cssNode.appendChild(document.createTextNode(this.funnel.style));
	      }
        document.getElementsByTagName("head")[0].appendChild(cssNode);
        this.stylesNode = cssNode;
    };

    Funnel.prototype.removeStyleNode = function() {
        if (this.stylesNode) {
            this.stylesNode.parentNode.removeChild(this.stylesNode);
            this.stylesNode = null;
        }
    };

    Funnel.prototype.openFunnelLanding = function (source, context) {
        var landingUrl = this.handler.baseUrl() + "/api/campaigns/" + this.campaignId + "/funnel" +
            "?d=" + this.trackingToken +
            "&t=" + this.handler.tracker.currentTrail +
            (source ? ("&funnel-source=" + source) : "");

        if (this.handler.language) {
          landingUrl = landingUrl + ("&lang=" + this.handler.language);
        }

        if (this.handler.channelLanguage) {
          landingUrl = landingUrl + ("&channel-lang=" + this.handler.channelLanguage);
        }

        if (context){
            var contextParams = "";
            for (var key in context){
                if(context.hasOwnProperty(key)){
                    contextParams += "&" + key + "=" + context[key];
                }
            }
            landingUrl += contextParams;
        }

        if (this.handler.onRedirect) {
            this.handler.onRedirect(landingUrl);
            return;
        }

        prisma.openFunnelPage(this.funnel, landingUrl);
    };

    Funnel.prototype.processFunnelStep = function (source, stepData, trackingToken, campaignId, context, onSuccess, onError) {
        var self = this;
        var url;
        if (typeof(self.sandboxHash) != "undefined") {
           url = "/api/campaigns/sandbox/" + self.sandboxHash + "/" + self.sandboxHost + "/advance-funnel-step";
        } else {
           url = "/api/campaigns/advance-funnel-step";
        }

        var query = new jsedn.Map([new jsedn.kw(":funnel-source"), source || "",
                                   new jsedn.kw(":tracking-token"), trackingToken,
                                   new jsedn.kw(":step-data"), stepData || null,
                                   new jsedn.kw(":campaign-id"), campaignId,
                                   new jsedn.kw(":trail-id"), self.handler.tracker.currentTrail,
                                   new jsedn.kw(":context"), context || null]);

        var onSuccessFn = function (response){
            var response = jsedn.parse(response);

            var newIdKey = new jsedn.kw(":new-customer-id");
            if (response.exists(newIdKey)) {
                self.customer = response.at(newIdKey);
                self.handler.customer = self.customer;
                self.handler.storeClientData("global-prisma-customer-id", self.customer, 365 * 24 * 60 * 60);
            }

            onSuccess(response);
        };

        self.handler.api.post(url, query, onSuccessFn, onError);
    };
    // Send a POST request to the current funnel step instance
    // He must implement the funnel/IFunnelRestRequest and can be return any data in edn format

    Funnel.prototype.restRequest = function (requestData, onSuccess, onError) {
        var self = this;
        var url;
        if (typeof(self.sandboxHash) != "undefined") {
           url = "/api/campaigns/sandbox/" + self.sandboxHash + "/" + self.sandboxHost + "/funnel-step-request";
        } else {
           url = "/api/campaigns/funnel-step-request";
        }
        requestData = requestData || new jsedn.Map();
        var targetS12nKw = new jsedn.kw(":target-s12n");
        if (self.currentView.stepData.exists(targetS12nKw) &&
            self.currentView.stepData.at(targetS12nKw) !== null) {
            requestData.set(targetS12nKw, self.currentView.stepData.at(targetS12nKw));
        }
        var query = new jsedn.Map([new jsedn.kw(":tracking-token"), self.trackingToken,
                                   new jsedn.kw(":step-data"), requestData,
                                   new jsedn.kw(":campaign-id"), self.campaignId,
                                   new jsedn.kw(":trail-id"), self.handler.tracker.currentTrail,]);

        var onSuccessFn = function (response){
            var response = jsedn.parse(response).jsEncode();
            onSuccess(response);
        };
        self.handler.api.post(url, query, onSuccessFn, onError);
    };



    Funnel.prototype.totalSteps = function() {
        return this.funnel.steps && this.funnel.steps.val && this.funnel.steps.val.length;
    };

    Funnel.prototype.getCurrentStepActions = function() {
       var def = this.funnel.steps.at(this.currentPosition);
       var cfg = def.at(jsedn.kw(":config"));
       var actions = cfg.at(jsedn.kw(":funnel-step-s12n/actions"));
       return jsedn.encode(actions);
    }

    Funnel.prototype.loadView = function() {
        var self = this;
        if (this.funnel.onSchedule == false) {
          this.showNotScheduledView();
          return;
        }
        if (this.funnel.customerValid == false) {
          this.showConversionView();
          return;
        }
        var f = this.funnel.steps.at(this.currentPosition);
        if (f) {
            var type = f.at(jsedn.kw(":funnel-step/type")).toString();
            var plugin = prisma.findPlugin(type);
            if (plugin) {
                this.currentView = new plugin(f, this);
                this.currentView.show();
                self.notifyEvent("viewLoaded");
                self.notifyEvent({
                    "eventType": "viewLoadedEx",
                    "hasActionBarSupport": this.currentView.hasActionBarSupport(),
                    "hasDismissActionSupport": this.currentView.hasDismissActionSupport()
                });
                if (typeof (PrismaHost) != "undefined" && (typeof PrismaHost.hostOnViewLoaded == "function")) {
                    var actionBarSupport = (this.currentView.hasActionBarSupport() == true).toString()
                    PrismaHost.hostOnViewLoaded(actionBarSupport);
                }
            }
        }else {
            if(this.fn_end) {
                this.fn_end();
            }
        }
    };

    function DomTracker() {

    }

    function LinkTracker() {

    }

    function Tracker(prisma, opts) {
        var self = this;
        self.opts = opts || {};
        self.api = prisma.api;
        self.prisma = prisma;
        self.channelLanguage = prisma.channelLanguage;
        self.language = prisma.language;
        self.customer = prisma.customer || "";
        self.pendingTracks = [];
        //if trace and trail are passed as options do not reset traces
        if (opts.traceId && opts.trailId) {
            self.currentTrace = opts.traceId;
            self.currentTrail = opts.trailId;
            return;
        }
        if (opts.reset) {
          self.reset();
          return;
        }
        self.currentTrace = prisma.readClientData("prisma-trace-id");
        if (!self.currentTrace) {
            self.registerTrace();
        }else {
            self.currentTrail = prisma.readClientData("prisma-trail-id");
            if (!self.currentTrail) {
                self.registerTrace();
            }
        }
    }

    Tracker.prototype.setTrace = function(traceId) {
        this.currentTrace = traceId;
    };

    Tracker.prototype.setTrail = function(trailId) {
        this.currentTrail = trailId;
    };

    Tracker.prototype.reset = function() {
        var self = this;
        //api initialized without token cannot start new trace
        //always finishes in last step, usually funnel rendered in
        //a new landing, it doesn't have an appToken
        if (self.api.appToken) {
            self.currentTrace = null;
            self.currentTrail = null;
            self.prisma.storeClientData("prisma-trace-id", "", -1);
            self.prisma.storeClientData("prisma-trail-id", "", -1);
            self.registerTrace();
        } else {
            self.currentTrace = null;
            self.currentTrail = null;
            self.prisma.storeClientData("prisma-trace-id", "", -1);
            self.prisma.storeClientData("prisma-trail-id", "", -1);
        }
    };

    Tracker.prototype.validCookies = function() {
        return this.prisma.readClientData("prisma-trace-id") && this.prisma.readClientData("prisma-trail-id");
    };

    Tracker.prototype.didFailToInitialize = function() {
        return !!this.trackerFailedToInitialize;
    };

    Tracker.prototype.isValid = function() {
        return !!this.currentTrace && !!this.currentTrail;
    };

    Tracker.prototype.registerTrace = function() {
        var self = this;
        var url = "/api/traces/trace";

        var customerReference = self.customer;
        if (typeof self.customer == "object") {
          var data = [];
          for (var k in self.customer){
             if (self.customer.hasOwnProperty(k)){
               data.push(new jsedn.kw(":" + k));
      	       data.push(self.customer[k]);
             }
          }
          customerReference = new jsedn.Map(data);
        }

        var query = new jsedn.Map([jsedn.kw(":customer-id"), customerReference,
                                   jsedn.kw(":app-token"), self.api.appToken]);

        var onSuccessFn = function(response) {
            response = jsedn.parse(response);
            self.currentTrace = response.at(jsedn.kw(":trace-id"));
            self.anonymous = response.at(jsedn.kw(":anonymous"));
            if ((typeof self.customer == "object") && !self.anonymous) {
                self.prisma.storeClientData("prisma-customer-query", JSON.stringify(self.customer), 365 * 24 * 60 * 60);
            }
            else {
                self.prisma.storeClientData("prisma-customer-query", "", -1);
            }
            self.customer = response.at(jsedn.kw(":customer-id"));
            //customer cookie lasts for a year
            self.prisma.storeClientData("prisma-customer-anon", self.anonymous ? "1" : "0", 365 * 24 * 60 * 60);
            self.prisma.storeClientData("global-prisma-customer-id", self.customer, 365 * 24 * 60 * 60);
            //by default a trace id lasts a month, 30 days, 24 hours, 60 minutes, 60 seconds each
            var maxAgeSeconds = response.exists(jsedn.kw(":max-age-seconds")) ? response.at(jsedn.kw(":max-age-seconds"))
                                                                              : 30 * 24 * 60 * 60;
            self.prisma.storeClientData("prisma-trace-id", self.currentTrace, maxAgeSeconds);
            self.startTrail(maxAgeSeconds);
        };

        var onErrorFn = function (error) {
          self.trackerFailedToInitialize = true;
          self.trackerInitError = error.data;

          if (window.console) {
            console.error("Error creating tracker: " + JSON.stringify(error));
          }
        };

        self.api.post(url, query, onSuccessFn, onErrorFn);
    };

    var isMobile = function() {
      var mobile = false; //initiate as false
      // device detection
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)) {
        mobile = true;
      }
      return mobile;
    }

    Tracker.prototype.startTrail = function(traceMaxAgeSeconds) {
        traceMaxAgeSeconds = traceMaxAgeSeconds || 1 * 24 * 60 * 60;
        var self = this;
        var url = prisma.format("/api/traces/{0}/trail",self.currentTrace);
        var browser = getBrowser();
        var params = [jsedn.kw(":identified"), self.anonymous === undefined ? false : !self.anonymous,
                      jsedn.kw(":platform"), (self.opts.platform || browser["name"]),
                      jsedn.kw(":channel"), (typeof self.opts.channel == "string" ? jsedn.kw(":" + self.opts.channel) :
					     (isMobile() ? jsedn.kw(":mobile") : jsedn.kw(":web")))]
        if (self.language) {
          params.push(jsedn.kw(":language"), self.language);
        }
        if (self.channelLanguage) {
          params.push(jsedn.kw(":channel-language"), self.channelLanguage);
        }
        var query = new jsedn.Map(params);
        var onSuccessFn = function(response) {
            response = jsedn.parse(response);
            self.currentTrail = response.at(jsedn.kw(":trail-id"));
            //trail id lasts a day
            self.prisma.storeClientData("prisma-trail-id", self.currentTrail, Math.min(traceMaxAgeSeconds, 1 * 24 * 60 * 60));
        };

        var onErrorFn = function(error) {
            self.trackerFailedToInitialize = true;

            if (error && error.data && error.data.status === 410) {
                self.reset();
            }

            if (window.console) {
              console.error("Error creating tracker: " + JSON.stringify(error));
            }
        };

        self.api.post(url, query, onSuccessFn, onErrorFn);
    };

    Tracker.prototype.resetOnError = function(onResetSuccessFn, onErrorFn) {
      var self = this;
      return function(error) {
        if (error && error.data && error.data.status == 410) {
            self.reset();
            var waiter = setInterval(function() {
                if (self.isValid()) {
                    onResetSuccessFn();
                    clearInterval(waiter);
                }
            }, 50);
        } else if (onErrorFn){
            onErrorFn();
        }
      }
    }

    Tracker.prototype.trackLocation = function() {
        if (this.opts.trackLocation == false) {
          return;
        }
        var uri = getPageLocation();
        var title = getPageTitle();
        this.track(uri, "location", {"title": title});
    };

    Tracker.prototype.trackEvent = function(event,parameters, callback) {
        this.track(event, "event", parameters, callback);
    };

    Tracker.prototype.track = function(name, type, parameters, callback) {
        var self = this;
        if (self.currentTrace && self.currentTrail) {
            self._track(name, type, parameters, callback);
        }
        else {
            self.pendingTracks.push({name : name,
                                     type : type,
                                     parameters : parameters});
            if (!self.asyncTracker) {
                self.asyncTracker = setInterval(function() {
                    if (self.trackPendingQueue()) {
                        clearInterval(self.asyncTracker);
                        self.asyncTracker = undefined;
                    }
                }, 1000);
            }
        }
    };

    Tracker.prototype._track = function(name, type, parameters, callback) {
        var self = this;
        var url = prisma.format("/api/traces/trail/{0}/action",self.currentTrail);
        var query = new jsedn.Map([jsedn.kw(":name"), name,
                                   jsedn.kw(":type"), jsedn.kw(":trail-action/" + type),
                                   jsedn.kw(":is-identification"), (parameters["is-identification"] || false)]);

        if (parameters) {
            var data = [];
            for (var p in parameters) {
                if (parameters.hasOwnProperty(p)) {
                    data.push(new jsedn.Map([jsedn.kw(":name"), p,
                                             jsedn.kw(":value"), parameters[p]]));
                }
            }
            query.set(jsedn.kw(":data"), data);
        }
        var onSuccessFn = function(response) {
            if (callback) {
              callback(response);
            }
        };
        var onErrorFn = function(error) {
            if (callback) {
              callback(null, error);
            }
        };
        self.api.post(url, query, onSuccessFn, onErrorFn);
    };

    Tracker.prototype.trackPendingQueue = function() {
        if (this.currentTrace && this.currentTrail) {
            for (var i = 0; i < this.pendingTracks.length; i++) {
                var t = this.pendingTracks[i];
                this._track(t.name, t.type, t.parameters);
            }
            return true;
        }
        return false;
    };

  function getFunnelNode(){
    var current = this;
    while (current && current.funnelNode == undefined) {
        current = current.parentNode;
    }
    if (current && current.funnelNode) {
        return current.funnelNode;
    }
  }
  //An empty funnel step to show a wait indicator before the first advance-funnel-step

  function startFunnel(source) {
      return getFunnelNode.call(this).startFunnel(source);
  }

  function closePopup() {
    return getFunnelNode.call(this).closePopup();
  }

  function dismissCampaign() {
    return getFunnelNode.call(this).dismissCampaign();
  }


  function BannerHTML (bannerDefinition, content, width, height, funnel, url, isolated, category, group, adaptToContent,context){
        this.content = b64_to_utf8(content);
        this.width = width;
        this.height = height;
        this.funnel = funnel;
        this.url = url;
        this.isolated = isolated;
        this.bannerDefinition = bannerDefinition;
        this.category = category;
        this.group = group;
        this.adaptToContent = adaptToContent;
        this.context = context;
    }

    BannerHTML.prototype.createAsIframe = function(parent, content) {
       var self = this;
       var iframeNode = document.createElement("iframe");
       this.iframeNode = iframeNode;
       iframeNode.style.width = hasClass(parent, "prisma_fluid") ? "100%" : self.width + "px";
       iframeNode.style.maxWidth = hasClass(parent, "prisma_fluid") ? "100%" : self.width + "px";
       iframeNode.style.height = hasClass(parent, "prisma_fluid") ? "100%" : self.height + "px";
       iframeNode.style.border = "0px";
       iframeNode.onload = function() {
           if (iframeNode.contentWindow != undefined) {
             self.setEventHandlers();
             iframeNode.contentWindow.document.write(content);
             var resets = 25;
             var it = setInterval(function(){
               if (iframeNode.contentWindow && iframeNode.contentWindow.prisma == undefined) {
                 self.setEventHandlers();
               }
               if(resets == 0){
                   clearInterval(it);
               }
               resets--;
             },50);
           }
       };
      parent.innerHTML = "";
      if (hasClass(parent, "prisma_responsive")) {
        var containerParentNode = document.createElement("div");
        containerParentNode.style.maxWidth = self.width + "px";

        var containerNode = document.createElement("div");
        containerNode.className = "prisma_iframe_responsive";
        containerNode.style.paddingBottom = (100 * (self.height / self.width)) + "%";

        containerNode.appendChild(iframeNode);
        containerParentNode.appendChild(containerNode);
        parent.appendChild(containerParentNode);

       } else {
        parent.appendChild(iframeNode);
       }
    };

    BannerHTML.prototype.resizeBanner = function() {
      if (this.iframeNode) {
        this.iframeNode.style.height = this.iframeNode.contentWindow.document.body.scrollHeight + "px";
        this.iframeNode.style.width = this.iframeNode.contentWindow.document.body.scrollWidth + "px"
      }
    }

    BannerHTML.prototype.createAsSnippet = function(parent, content) {
       var self = this;
       var embeddedNode = document.createElement("div");
       embeddedNode.funnelNode = self;
       embeddedNode.innerHTML = content;
       parent.innerHTML = "";
       if (hasClass(parent, "prisma_responsive")) {
           embeddedNode.style.width = "100%";
           embeddedNode.style.height = "100%";

           var containerParentNode = document.createElement("div");
           containerParentNode.style.maxWidth = self.width + "px";

           var containerNode = document.createElement("div");
           containerNode.style.paddingBottom = (100*(self.height/self.width)) + "%";
           containerNode.style.height = 0;

           containerNode.appendChild(embeddedNode);
           containerParentNode.appendChild(containerNode);
           parent.appendChild(containerParentNode);

        } else {
           embeddedNode.style.width = self.width + "px";;
           embeddedNode.style.maxWidth = self.width + "px";
           embeddedNode.style.height = self.height + "px";
           // TODO
           // embeddedNode.style.overflow = "auto";

           parent.appendChild(embeddedNode);
        }
        this.embeddedNode = embeddedNode;
    };

    BannerHTML.prototype.allowsEmbedding = function(content) {
      if (content.match(/<\s*head\s*>/) ||
          content.match(/<\s*html\s*>/) ||
          content.match(/<\s*body\s*>/)) {
        return false;
      }
      return true;
    };

    BannerHTML.prototype.dismissCalled = function() {
      if (this.content.indexOf("prisma.dismissCampaign.call(this)") !== -1) {
        return true;
      }
      return false;
    };

    BannerHTML.prototype.show = function(parent){
      var self = this;
      if (self.isolated == false && self.allowsEmbedding(self.content)) {
         self.createAsSnippet(parent, self.content);
      }
      else {
         self.createAsIframe(parent, self.content);
      }
      this.parent = parent;
    };

    BannerHTML.prototype.startFunnel = function(source) {
       var self = this;
       if(self.on_funnel_start) {
            self.on_funnel_start();
        }
        self.closePopup();
        self.funnel.start(function(){
                             if(self.funnel_return) {
                                 self.funnel_return();
                             }
                          }, source,self.context);
    };

    BannerHTML.prototype.closePopup = function() {
      if (this.bannerDefinition.popupWindow){
        this.bannerDefinition.popupWindow.hide();
      }
    };

    BannerHTML.prototype.dismissCampaign = function() {
      this.funnel.dismiss();
      if (this.bannerDefinition.popupWindow){
        this.bannerDefinition.popupWindow.hide();
      }
    };

    BannerHTML.prototype.setEventHandlers = function() {
      if (!this.iframeNode || !this.iframeNode.contentWindow) {
        return;
      }
      var self = this;
      this.iframeNode.contentWindow.prisma = { startFunnel : function (source) { self.startFunnel(source);},
                                               closePopup : function () { self.closePopup();},
                                               resizeBanner : function () {self.resizeBanner();},
                                               dismissCampaign : function () { self.dismissCampaign();}};
      if (this.mouseover && !this.mouseoverSet) {
          var html = this.iframeNode.contentWindow.document.getElementsByTagName("html");
          if (html.length > 0) {
              this.mouseoverSet = true;
              setEvent(html[0],"mouseover",this.mouseover);
          }
      }
      if (this.mouseout  && !this.mouseoutSet) {
          var html = this.iframeNode.contentWindow.document.getElementsByTagName("html");
          if (html.length > 0) {
              this.mouseoutSet = true;
              setEvent(html[0],"mouseout",this.mouseout);
          }
      }
    };

    BannerHTML.prototype.onFunnelStart = function (fn_handler){
        this.on_funnel_start = fn_handler;
    };

    BannerHTML.prototype.onFunnelReturn = function (fn_handler){
        this.funnel_return = fn_handler;
    };

    BannerHTML.prototype.onSwipe = function (fn_handler){
        this.swipe = fn_handler;
    };

    BannerHTML.prototype.onMouseOver = function (fn_handler){
        this.mouseover = fn_handler;
        if (this.iframeNode && this.iframeNode.contentWindow){
            this.setEventHandlers();
        }
        if (this.embeddedNode) {
            setEvent(this.embeddedNode,"mouseover",this.mouseover);
        }
    };

    BannerHTML.prototype.onMouseOut = function (fn_handler){
        this.mouseout = fn_handler;
        if (this.iframeNode && this.iframeNode.contentWindow){
            this.setEventHandlers();
        }
        if (this.embeddedNode) {
            setEvent(this.embeddedNode,"mouseout",this.mouseout);
        }
    };

    BannerHTML.prototype.opacity = function (o){
        this.parent.style.opacity = o;
    };
    BannerHTML.prototype.horizontalpos = function (x){
        this.parent.style.left = x +"px";
    };

    function BannerImage (bannerDefinition, link, width, height, funnel, url, openInNewWindow, category, group, adaptToContent, context, altText){
        this.link = link;
        this.width = width;
        this.height = height;
        this.funnel = funnel;
        this.url = url;
        this.bannerDefinition = bannerDefinition;
        this.openInNewWindow = openInNewWindow;
        this.category = category;
        this.group = group;
        this.context = context;
        this.adaptToContent = adaptToContent;
        this.altText = altText;
    }

    BannerImage.prototype.opacity = function (o){
        this.parent.style.opacity = o;
    };
    BannerImage.prototype.horizontalpos = function (x){
        this.parent.style.left = x +"px";
    };

    BannerImage.prototype.dismissCalled = function() {
      return false;
    };

    BannerImage.prototype.closePopup = function() {
      if (this.bannerDefinition.popupWindow){
        this.bannerDefinition.popupWindow.hide();
      }
    };

    BannerImage.prototype.show = function(parent){
        var self = this;
        var createImage = true;
        var images = parent.getElementsByTagName('img');
        for (var i = 0;i< images.length;i++){
            var n = images[i];
            if(n.prisma_src == self.link){
                createImage = false;
                this.img = n;
                break;
            }
        }
        if(createImage){
            var img = document.createElement("img");
            img.className = "prisma_banner";
            img.setAttribute("src",self.link);
            if (self.altText) {
                img.setAttribute("alt", self.altText);
            }
            img.prisma_src = self.link;

            if (!self.funnel || self.funnel.funnel && !self.funnel.funnel.hasSteps){
                prisma.addClass(img, "c-default");
            }
            else if(self.funnel && self.funnel.funnel && self.funnel.funnel.hasSteps) {
              setEvent(img, "click", function(e) {
                e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                if(self.on_funnel_start) {
                  self.on_funnel_start();
                }
                self.closePopup();
                self.funnel.start(function(){
                  if(self.funnel_return) {
                    self.funnel_return();
                  }
                },null,self.context);
                return false;
              });
            }
            else if(self.url && trim(self.url) != "") {
                setEvent(img, "click", function() {
                    if (self.openInNewWindow) {
                        prisma.openWindow(self.url);
                    }
                    else {
                        window.top.location.href = self.url;
                    }
                });
            }
            parent.innerHTML = "";
            parent.appendChild(img);
            this.img = img;
        }
        if(this.mouseover)
            setEvent(this.img,"mouseover",this.mouseover);
        if(this.mouseout)
            setEvent(this.img,"mouseout",this.mouseout);
        if(this.swipe){
            detectswipe(this.img,this.swipe);
        }
        this.parent = parent;

    };
    BannerImage.prototype.onSwipe = function (fn_handler){
        this.swipe = fn_handler;
        if (this.img){
            detectswipe(this.img,this.swipe);
        }
    };

    BannerImage.prototype.onMouseOver = function (fn_handler){
        this.mouseover = fn_handler;
        if (this.img){
            setEvent(this.img,"mouseover",this.mouseover);
        }
    };
    BannerImage.prototype.onMouseOut = function (fn_handler){
        this.mouseout = fn_handler;
        if (this.img){
            setEvent(this.img,"mouseout",this.mouseout);
        }
    };
    BannerImage.prototype.onFunnelStart = function (fn_handler){
        this.on_funnel_start = fn_handler;
    };
    BannerImage.prototype.onFunnelReturn = function (fn_handler){
        this.funnel_return = fn_handler;
    };

    function Carousel (frames,width,height,wait_time,mode){
        this.width = width;
        this.height = height;
        wait_time = wait_time==null || wait_time==undefined ? 2000:wait_time;
        var loop_delay = 10; // Milisecs for the ensureVisibleLoop
                             // Desired and current position matching watch dog

        var step_size = mode == MODE_HORIZONTAL_SCROLL?8:0.05; // How much advance or backward in a step
                                                               // looking for the desired value ?.
                                                               // 8 pixel for scroll 0.05 for opacity

        var forward = true;
        var auto_ff = true;
        var dots = [];
        var current_dot;
        this.dv = document.createElement("div");
        var classname = "prisma_carrusel";
        var classsize = "";
        if(width <= 150 ){
            classsize = " carrusel_sm";
        }else if(width <= 400){
            classsize = " carrusel_md";
        }
        this.dv.className = classname + " " + classsize;
        this.dv.style.width = width+"px";
        var bk = document.createElement("div");
        this.bk = bk;
        bk.className = "carrusel-images";
        bk.style.overflow = "hidden";

        var divDots = document.createElement("div");
        var dotList = document.createElement("ul");
        divDots.appendChild(dotList);
        divDots.className = "carrusel-dots";
        dotList.className = "inline-list";
        var self = this;
        var current_step_size = step_size;
        var current = 1;
        var desired = 1;
        var over_count = 0;
        var funnels_started = 0;
        var pause_timeout;
        var band_width = mode==MODE_HORIZONTAL_SCROLL?width:1; //band_width for scroll is a placeholder width
                                                               //for fade es opacity range 0-1
                                                               //How much move to complete a cycle
                                                               //and stay in the next image?
        var sc = band_width;

        var pause = function (callback){
            pause_timeout = setTimeout(function(){
                pause_timeout=null;
                callback();
            },wait_time);
        };
        var cancel_pause = function(){
            if(pause_timeout){
                clearTimeout(pause_timeout);
                pause_timeout=null;
            }

        };

        var show_frame = function(index){
            frames[index].opacity(1);
            frames[index].horizontalpos(0);

        };
        var hide_frame = function(index){
            frames[index].opacity(0);
            frames[index].horizontalpos(-10000);
        };

        var ensureVisibleLoop = function(){
            setInterval(function(){
                if(mode == MODE_HORIZONTAL_SCROLL){
                   bk.scrollLeft = sc;
                }
                if(current != desired){

                    if(mode == MODE_FADEIN_FADEOUT){
                        frames[current].horizontalpos(0);
                        frames[desired].horizontalpos(0);
                    }
                    var c;
                    if(forward){
                        sc += Math.min((desired * band_width - sc),current_step_size);
                        c = parseInt(sc  / band_width);

                    }else {
                        sc -=Math.max((desired * band_width - sc),current_step_size);
                        c = parseInt((sc+band_width)  / band_width);
                    }
                    if(mode==MODE_FADEIN_FADEOUT){
                        if(c==desired){
                            hide_frame(current);
                            show_frame(c);
                        }
                    }
                    current = c;
                    // dot
                    var r = current;
                    if(r == frames.length-1){
                        r = 1;
                    }
                    if(r == 0){
                        r = frames.length-2;
                    }
                    r--;
                    if(dots[r]!=current_dot){
                        current_dot.className = "";
                        current_dot = dots[r];
                        current_dot.className = "active";
                    }
                    if(current==desired){
                        if(current == frames.length-1){
                            if(mode == MODE_FADEIN_FADEOUT){
                                hide_frame(current);
                                show_frame(1);
                            }
                            current=1;
                        }else if(current == 0){
                           if(mode == MODE_FADEIN_FADEOUT){
                                hide_frame(current);
                                show_frame(frames.length-2);
                           }
                           current = frames.length-2;
                        }
                        sc = current * band_width;
                        desired = current;
                        current_step_size = step_size;
                        if (mode == MODE_FADEIN_FADEOUT){
                            for (var i=0;i<frames.length;i++) // #787
                                                              // When by action of a click on a dot the "desired" frame
                                                              // suddenly changes the current "desired" image remains with
                                                              // position 0 instead of -1000 (fade mode).This small cleaning
                                                              // solves the problem by ensuring that only the "current" is visible
                                                              // when the transition has finalized

                                if(i!=current)
                                    hide_frame(i);
                        }
                    }
                    if(mode == MODE_FADEIN_FADEOUT){
                        var fade_in,fade_out;
                        if(forward){
                            fade_in = (sc - (current * band_width));
                        } else {
                            fade_in = ((current * band_width) - sc);
                        }

                        fade_out = (band_width - fade_in);
                        frames[desired].opacity(fade_in);
                        frames[current].opacity(fade_out);
                    }

                }else{
                    if(auto_ff){
                        if(!pause_timeout){
                            pause(function(){
                                if(current == desired && over_count==0 && funnels_started == 0){
                                    if(forward){
                                        desired++;
                                    }else{
                                        desired--;
                                    }
                                 }
                            });
                        }
                    }
                }
            },loop_delay);
        };
        for (var i = 0;i< frames.length;i++){
            var f = document.createElement("div");
            f.style.position = "absolute";
            if (mode == MODE_HORIZONTAL_SCROLL){
                f.style.left = (i* width) +"px";
            }
            frames[i].show(f);
            if(mode == MODE_FADEIN_FADEOUT){
                if (i == current)
                    show_frame(i);
                else
                    hide_frame(i);
            }
            frames[i].onMouseOver(function(){
                over_count ++;
            });
            frames[i].onMouseOut(function(){
                over_count--;
            });
            frames[i].onFunnelStart(function(){
                funnels_started ++;
            });
            frames[i].onFunnelReturn(function(){
                funnels_started --;
            });
            if(mode == MODE_HORIZONTAL_SCROLL){
                (function(i){
                    frames[i].onSwipe(function(el,direction){


                        if(current == desired){
                           cancel_pause();
                           current_step_size=30;

                           if(direction == "r"){
                              forward=false;
                              desired--;
                           }
                           if(direction == "l"){
                              forward=true;
                              desired++;
                           }
                        }
                    });
                })(i);
            }
            if(i<frames.length-1 && i!=0){
                var dot = document.createElement("li");
                var a = document.createElement("a");
                if(i==1){
                    a.className = "active";
                    current_dot = a;
                }
                dots.push(a);
                dot.appendChild(a);
                (function(i){
                    setEvent(a,"click",function(){
                        cancel_pause();
                        if(current< i)
                            forward=true;
                        else
                            forward=false;
                        if(mode == MODE_HORIZONTAL_SCROLL) // Click the dot increase loop speed
                            current_step_size=35;          // after loop completion current_step_size is restored
                        else                               // to default defined by step_size
                            current_step_size= 1;          // instant change for fade mode
                        desired=i;
                    });
                })(i);
                dotList.appendChild(dot);
            }
            bk.appendChild(f);
        }
        if(mode == MODE_HORIZONTAL_SCROLL){
            bk.scrollLeft = width;
        }else
            frames[current].opacity(1);
        ensureVisibleLoop();
        var btnNext = document.createElement("a");
        btnNext.className = "carrusel-right";
        var spnNext = document.createElement("span");
        spnNext.className = "prisma-arrow";
        btnNext.appendChild(spnNext);

        setEvent(btnNext,"click",function(){
            if(current == desired){
                cancel_pause();
                forward=true;
                if(mode == MODE_HORIZONTAL_SCROLL)
                    current_step_size=15;
                desired++;
            }
        });
        var btnPrev = document.createElement("a");
        btnPrev.className = "carrusel-left";
        var spnPrev = document.createElement("span");
        spnPrev.className = "prisma-arrow";
        btnPrev.appendChild(spnPrev);
        setEvent(btnPrev,"click",function(){
            if(current == desired){
                cancel_pause();
                forward=false;
                if(mode == MODE_HORIZONTAL_SCROLL) // Click on the arrow, the horizontal speed is increased.
                    current_step_size=15;
                desired--;
            }
        });
        this.dv.appendChild(btnPrev);

        this.dv.appendChild(bk);
        this.dv.appendChild(btnNext);
        this.dv.appendChild(divDots);
    }
    Carousel.prototype.show = function (parent){
        var r = [];
        // mark div with data-prisma-carrousel attribute and
        // check and delete carousel instances in the parent
        // before insert it.

        this.dv.setAttribute("data-prisma-carrousel","true");
        for (i=0;i< parent.childNodes.length;i++){
            var d = parent.childNodes[i];
            if (d && d.getAttribute && d.getAttribute("data-prisma-carrousel")==="true")
                r.push(d);
        }
        for (i=0;i< r.length;i++){
            parent.removeChild(r[i]);
        }
        parent.appendChild(this.dv);
        if (hasClass(parent, "prisma_responsive")) {
            this.bk.style.paddingBottom = (100 * (this.height / this.width)) + "%";
        } else {
            this.bk.style.height = this.height +"px";
        }
    };

    function Banner(handler, placeholderId, elementId, fallbackUrl, context, unrolled, isolated, adaptToContent, banner_config) {
        var self = this;
        self.handler = handler;
        self.fallbackUrl = fallbackUrl;
        self.context = context;
        self.placeholderId = placeholderId;
        self.elementId = elementId;
        self.unrolled = unrolled;
        self.isolated = isolated;
        self.adaptToContent = adaptToContent;
    }

    Banner.prototype.clear = function() {
        var parent = document.getElementById(this.elementId);
        if (parent) {
            parent.innerHTML = "";
        }
    }

    Banner.prototype.load = function(banners_config) {
        var self = this;
        if (typeof banners_config == "undefined" || !banners_config) {
          if (self.fallbackUrl) {
              var parent = document.getElementById(self.elementId);
              if (!parent) {
                if (window.console){
                   console.error(self.elementId + " unable to create banner, element not found");
                }
                return;
              }
              var img = document.createElement("img");
              img.className = "prisma_banner";
              img.setAttribute("src", self.fallbackUrl);
              parent.innerHTML = "";
              parent.appendChild(img);
           }
           self.images = [];
           return;
        }
        var banners = banners_config.at(jsedn.kw(":banners-list"));
        var isPopup = banners_config.exists(jsedn.kw(":is-popup")) ? banners_config.at(jsedn.kw(":is-popup")) : false;
        var popupTimeout = banners_config.exists(jsedn.kw(":popup-timeout")) ? banners_config.at(jsedn.kw(":popup-timeout")) : null;
        var div;

        if (self.elementId && !isPopup) {
            div = document.getElementById(self.elementId);
            if (!div) {
              if (window.console){
                 console.error(self.elementId + " element not found, unable to create banner, please verify an element with that identifier exists on the current page.");
              }
              return;
            }
        }

        var makeContent = function(index){
            var bannerData = banners.at(index).at(jsedn.kw(":banner"));
            self.translations = banners.at(index).exists(jsedn.kw(":translations")) ? banners.at(index).at(jsedn.kw(":translations")).jsEncode() : null;
            var funnel = banners.at(index).exists(jsedn.kw(":funnel"))
                                        ? new Funnel(self,
                                                     self.handler.customer,
                                                     bannerData.at(jsedn.kw(":campaign")),
                                                     self.handler,
                                                     banners.at(index).at(jsedn.kw(":funnel")),
                                                     bannerData.at(jsedn.kw(":tracking-token")))
                                       : null;
            var categoryName = bannerData.at(jsedn.kw(":category-name"));
            var groupName = bannerData.at(jsedn.kw(":group-name"));
            if (bannerData.exists(jsedn.kw(":link"))) {
                var link = bannerData.at(jsedn.kw(":link"));
                var imageUrl = ((link.indexOf("http://") > -1 ||
                                 link.indexOf ("https://") > -1) ? "" : self.handler.baseUrl())
                               + link;
                return new BannerImage(self,
                                       imageUrl,
                                       bannerData.at(jsedn.kw(":width")),
                                       bannerData.at(jsedn.kw(":height")),
                                       funnel,
                                       bannerData.exists(jsedn.kw(":url")) ? bannerData.at(jsedn.kw(":url")) : null,
                                       bannerData.exists(jsedn.kw(":url-open-in-new-window")) ? bannerData.at(jsedn.kw(":url-open-in-new-window")) : false,
                                       categoryName,
                                       groupName,
                                       self.adaptToContent,
                                       self.context,
                                       bannerData.exists(jsedn.kw(":image-alt")) ? bannerData.at(jsedn.kw(":image-alt")) : null);
            }
            else if (bannerData.exists(jsedn.kw(":html-content"))) {
              var htmlContent = bannerData.at(jsedn.kw(":html-content"));
              return new BannerHTML(self,
                                    htmlContent,
                                    bannerData.at(jsedn.kw(":width")),
                                    bannerData.at(jsedn.kw(":height")),
                                    funnel,
                                    bannerData.exists(jsedn.kw(":url")) ? bannerData.at(jsedn.kw(":url")) : null,
                                    self.isolated,
                                    categoryName,
                                    groupName,
                                    self.adaptToContent,
                                    self.context);
            }
        };

        var childs = [];
        if (banners && banners.val.length > 0 ) {
           if(banners.val.length > 1) {
               for(var i = 0;i< banners.val.length;i++){
                    var banner = banners.at(i).at(jsedn.kw(":banner"));
                    childs.push(makeContent(i));
               }
               self.transitionTime =  banners_config.exists(jsedn.kw(":transition-time")) ? banners_config.at(jsedn.kw(":transition-time")) : null;
               if (div) {
                   if (self.unrolled) {
                        self.makeUnrolledCarousel(div, childs);
                    }
                    else {
                     //this hack repeating first and last image is for smooth
                     //scrolling when positioned on the edges of the carousel
                      var mode;
                      childs.unshift(makeContent(banners.val.length-1));
                      childs.push(makeContent(0));

                      var carouselMode = banners_config.exists(jsedn.kw(":carousel-mode")) ? banners_config.at(jsedn.kw(":carousel-mode")).toString() : ":horizontal-scroll";
                      switch(carouselMode){
                        case ":fadein-fadeout":
                            mode = MODE_FADEIN_FADEOUT;
                            break;
                        default:
                            mode = MODE_HORIZONTAL_SCROLL;
                      }

                      (new Carousel(childs,banners_config.at(jsedn.kw(":width")),
                                    banners_config.at(jsedn.kw(":height")),
                                    banners_config.exists(jsedn.kw(":transition-time"))
                                    ? banners_config.at(jsedn.kw(":transition-time")): null,
                                    mode)).show(div);
                    }
                }
            } else {
                var banner = makeContent(0);
                childs.push(banner);
                if (isPopup) {
                    var width = banners_config.at(jsedn.kw(":width"));
                    var height = banners_config.at(jsedn.kw(":height"));
                    var hideClose = banners_config.exists(jsedn.kw(":popup-show-close")) ? !banners_config.at(jsedn.kw(":popup-show-close")) : true;
                    var showDismiss = banner.funnel && banner.funnel.funnel &&
                        banner.funnel.funnel.allowDismiss &&
                        !banner.dismissCalled();

                    var dismissFn = function () {
                        banner.funnel.dismiss();
                    };

                    var preventDefault = false;
                    if (self.handler.onPopup) {
                        preventDefault = self.handler.onPopup({
                            width: width,
                            height: height,
                            banner: banner,
                            hideClose: hideClose,
                            dismissFn: showDismiss ? dismissFn : null
                        });
                    }
                    //if onPopup returned true the popup was handled by the client app, do not call
                    if (preventDefault != true) {
                        self.popupWindow = new PopUp(self, width, height, banner.adaptToContent, self.context);
                        self.popupWindow.hideClose = hideClose;
                        self.popupWindow.waitForContent = false;
                        if (showDismiss) {
                            self.popupWindow.setDismissFn(dismissFn);
                        }
                        self.popupWindow.show();
                        div = document.getElementById(self.popupWindow.getContentId());
                    }
                }
                if (div) {
                    if (self.unrolled) {
                        self.makeUnrolledCarousel(div, childs);
                    }
                    else {
                        childs[0].show(div);
                    }
                }
            }
            self.images = childs;
        }
        else {
          self.images = [];
        }
    };

    Banner.prototype.makeUnrolledCarousel = function(parent, bannerImages) {
      var unrolledHead = document.createElement("ul");
      unrolledHead.className = "unrolled-banner-list";
      for (var i = 0; i < bannerImages.length; i++) {
        var unrolledEntry = document.createElement("li");
        unrolledEntry.className = "unrolled-banner-entry";
        bannerImages[i].show(unrolledEntry);
        unrolledHead.appendChild(unrolledEntry);
      }
      parent.innerHTML = "";
      parent.appendChild(unrolledHead);
    };

    var localStoragePrefix = "prisma-campaigns.";
    function Prisma(proto,server, port, appToken, customer, opts) {
        opts = opts  || {};
        this.localStorage = opts.storageType == "local" || isMobile();
        var currentCustomer = this.readClientData("global-prisma-customer-id");
        var anonymous = this.readClientData("prisma-customer-anon") == "1";
        var lastQuery = this.readClientData("prisma-customer-query");
        var queryChangedFn = function(oldQueryStr, newQuery) {
          if (typeof oldQueryStr == "string" && oldQueryStr.length > 0) {
              var newQueryStr = JSON.stringify(newQuery);
              return oldQueryStr != newQueryStr;
          }
          else {
              return true;
          }
        }
        //if a string is received as customer id, assume it's the proper id and use it
        //if current id is anonymous and a search object is received, use it to search
        //otherwise, keep the current identified customer is present
        var execQuery = ((anonymous || queryChangedFn(lastQuery, customer)) && (typeof customer == "object"));
        this.customer = (customer && (typeof customer != "object") && customer.toString().length > 0) ?
                              customer.toString() :
                              execQuery ? customer : (currentCustomer || customer);
        this.proto = proto;
        this.server = server;
        this.port = port;
        this.reloadPopupsOnReset = (opts && opts.reloadPopupsOnReset) || false;
        this.language = (opts && opts.language);
        this.channelLanguage = window.navigator.userLanguage || window.navigator.language;
        this.banners = {};
        this.onRedirect = opts && opts.onRedirect;
        this.onPopup = opts && opts.onPopup;
        this.pageName = opts && opts.pageName;
        this.onConfirm = opts && opts.onConfirm;
        this.alwaysUseLanding = opts && opts.alwaysUseLanding;
        this.api = new prisma.api(server, port, appToken,proto);
        opts = opts || {};
        opts.reset = (currentCustomer != "" && this.customer != currentCustomer && !anonymous) || execQuery;
        this.tracker = new Tracker(this, opts);
    };

    Prisma.prototype.readClientData = function(key) {
        if (this.localStorage && window.localStorage) {

            // This is a trick, an environment that has been switched to
            // local storage should have valid cookies.
            // The stored cookie data is used until it expires.

            var cookieData = readCookie(key);
            if (cookieData) {
                return cookieData;
            } else {
                var keyData;
                try {
                    keyData = jsedn.parse(window.localStorage.getItem(localStoragePrefix + key));
                } catch (err) { /* nothing to do */ }
                if (keyData && keyData.exists(new jsedn.kw(":value"))) {
                    if (keyData.exists(new jsedn.kw(":expires"))) {
                        var expires = keyData.at(new jsedn.kw(":expires"));
                        var now = new Date();
                        if (expires > now) {
                            return keyData.at(new jsedn.kw(":value"));
                        }
                    } else {
                        return keyData.at(new jsedn.kw(":value"));
                    }
                }
            }
        } else {
            return readCookie(key)
        }
    };

    Prisma.prototype.storeClientData = function(key, value, maxAgeSeconds) {
        var expires;
        if (maxAgeSeconds > 0) {
            expires = new Date();
            expires.setTime(expires.getTime() + (maxAgeSeconds * 1000));
        }
        if (this.localStorage && window.localStorage) {
            var ednData = []
            ednData.push(new jsedn.kw(":value"));
            ednData.push(value);
            if (expires) {
                ednData.push(new jsedn.kw(":expires"));
                ednData.push(expires);
            }
            return localStorage.setItem(localStoragePrefix + key, jsedn.encode(new jsedn.Map(ednData)));
        } else {
            return createCookie(key, value, maxAgeSeconds)
        }
    };

    Prisma.prototype.baseUrl = function() {
       return this.proto + "//" + this.server + ":" + this.port;
    };

    Prisma.prototype.showUnsubscribe = function(triggeringParent, element, style, campaignId, campaignName, categoryName, groupName, unsubscriptionType, trailId, translationsTypes) {
        var u = new Unsubscription(this, triggeringParent, campaignId, campaignName, categoryName, groupName, unsubscriptionType, trailId, style, translationsTypes);
        u.show(element);
    };

    Prisma.prototype.showCommunicationPreferences = function(triggeringParent, element, style, settings,
                                                             campaignId, customerId, trailId, translationsTypes,
                                                             categories, groups, disabledChannels) {
        var u = new CommunicationPreferences(this, triggeringParent, style, settings,
                                             campaignId, customerId, trailId, translationsTypes,
                                             categories, groups, disabledChannels);
        u.show(element);
    };

    Prisma.prototype.refreshPage = function() {
        var self = this;
        if (self.tracker.isValid()) {
            self.customer = self.tracker.customer;
            self.tracker.trackLocation();
            self.banners = {};
            // backward compatible
            if (self.createBanners) {
                self.createBanners();
                self.createPopup();
            }
            if (self.loadedOnPage){
                //force previousBanners usage
                self.syncPage(null, self.reloadPopupsOnReset);
            }
            return true;
        }
        return false;
    }

    Prisma.prototype.reset = function() {
        var self = this;
        self.resetting = true;
        self.tracker.reset();
        //do not reload banners until new tracker is ready
        var waiter = setInterval(function() {
          if (self.refreshPage()) {
              self.resetting = false;
              clearInterval(waiter);
          }
        }, 50);
    };

    Prisma.prototype.startWatcher = function() {
        var self = this;
        var watcher = setInterval(function() {
          if (!self.tracker.validCookies() && !self.resetting) {
              if (window.console) {
                  console.log("Tracker cleanup detected, resetting.");
              }
              self.reset();
          }
        }, 20);
    }

    Prisma.prototype.syncPageAPI = function (banners, syncPopups, onSuccess) {
        var self = this;
        var url = "/api/campaigns/sync-page?customer-id=" + validCustomerId(this.customer);
        var placeholders = [];
        for (var i = 0; i < banners.length; i++) {
          var vars = [];
          vars.push(jsedn.kw(":placeholder/name"));
          vars.push(banners[i].placeholderId || "");
          if (typeof banners[i].dependOn != "undefined") {
              vars.push(jsedn.kw(":placeholder/depend-on"));
              vars.push(banners[i].dependOn);
          }
          if (typeof banners[i].context != "undefined") {
              for (var v in banners[i].context) {
                if (banners[i].context.hasOwnProperty(v)) {
                  vars.push(jsedn.kw(":" + v));
                  vars.push(banners[i].context[v]);
                }
              }
          }
          placeholders.push(new jsedn.Map(vars));
        }
        var params = [jsedn.kw(":customer-id"), validCustomerId(this.customer),
                      jsedn.kw(":placeholders"), placeholders,
                      jsedn.kw(":app-token"), self.api.appToken,
                      jsedn.kw(":sync-popups"), typeof syncPopups == "boolean" ? syncPopups : true,
                      jsedn.kw(":page-location"), self.pageName || getPageLocation(),
                      jsedn.kw(":trail-id"), (self.tracker.currentTrail || 0)];

        if (self.language) {
            params.push(jsedn.kw(":language"), self.language);
        }
        if (self.channelLanguage) {
            params.push(jsedn.kw(":channel-language"), self.channelLanguage);
        }
        var query = new jsedn.Map(params);
        var onSuccessFn = function(response) {
            var response = jsedn.parse(response);
             self.customer = response.at(jsedn.kw(":customer-id"));
             self.globalTranslations = response.at(jsedn.kw(":translations")).jsEncode() ;
             self.tracker.customer=self.customer;
             onSuccess(response);
        };
        self.api.post(url, query, onSuccessFn,
          self.tracker.resetOnError(function() {
                                      self.syncPageAPI(banners, syncPopups, onSuccess);
                                    }, onSuccess));
    };

    Prisma.prototype.activeCampaigns = function (customerId, category, group, onSuccess) {
        var self = this;
        var url = "/api/campaigns/active";
        var params = [jsedn.kw(":customer-id"),validCustomerId(this.customer || customerId),
                      jsedn.kw(":category"), category || "",
                      jsedn.kw(":group"), group || "",
                      jsedn.kw(":app-token"), self.api.appToken,
                      jsedn.kw(":trail-id"), (self.tracker.currentTrail || 0)];

        var query = new jsedn.Map(params);
        var onSuccessFn = function(response) {
            var campaigns = jsedn.parse(response);
            onSuccess(jsedn.toJS(campaigns));
        };
        self.api.post(url, query, onSuccessFn,
          self.tracker.resetOnError(function() {
                                      self.activeCampaigns(customerId, category, group, onSuccess);
                                    }, onSuccess));
    };

   Prisma.prototype.syncPage = function(banners, syncPopups) {
      var self = this;
      //if a null parameter was passed use the previous list of placeholders or default to empty
      self.previousBanners = banners || self.previousBanners || [];
      banners = self.previousBanners;
      //normalize banner vector to a list of objects
      if (banners.length > 0 && (typeof banners[0]) == "string") {
          tmp = [];
          for(idx = 0; idx < banners.length; idx += 2){
            tmp.push({placeholderId: banners[idx],
                       elementId: banners[idx + 1]});
          }
          banners = tmp;
       }
       //initialize local array of banner objects
       for (var i = 0; i < banners.length; i++){
           //if the placeholder name was already used set the map key name with the corresponding vector index to avoid collisions
           var phKey = typeof self.banners[banners[i].placeholderId] == "undefined" ? banners[i].placeholderId : (banners[i].placeholderId + "_" + i);
           self.banners[phKey] = new Banner(this, banners[i].placeholderId, banners[i].elementId, banners[i].fallbackUrl,
                                            banners[i].context, banners[i].unrolled, banners[i].isolated, banners[i].adaptToContent);
           //initialization on an empty images vector depends on this code path, to maintain
           //backward compatibility getBestBanners depends on images being undefined
           self.banners[banners[i].placeholderId].images = [];
       }
       self.syncPageAPI(banners, syncPopups,
        function(response) {
          if (typeof response != "undefined") {
              self.updateCustomerId(response.at(jsedn.kw(":customer-id")));
              var phResponses = response.at(jsedn.kw(":placeholders")).val;
              if (response.exists(jsedn.kw(":popup"))) {
                  var popupResponse =  response.at(jsedn.kw(":popup"));
                  self.createPopup(popupResponse);
              }
              //process received responses for each placeholder
              for (var i = 0; i < phResponses.length; i++) {
                  var banner_config = phResponses[i];
                  var phName = banner_config.at(jsedn.kw(":placeholder"));
                  var phIndex = banner_config.at(jsedn.kw(":index"));
                  //if the indexed version of the placeholder exist, use that, otherwise use the plain placeholder name
                  var phKey = typeof self.banners[phName + "_" + phIndex] != "undefined" ? phName + "_" + phIndex : phName;
                  var banner = self.banners[phKey].load(banner_config);
                  self.banners[phKey].synced = true;
              }
              //all placeholders that didn't receive synced information get cleared
              for (var phKey in self.banners) {
                if (self.banners.hasOwnProperty(phKey) && !self.banners[phKey].synced) {
                    self.banners[phKey].clear();
                }
              }
              self.notifyLoaded(true);
          }
          else {
            self.notifyLoaded(false);
          }
       });
   };

   Prisma.prototype.updateCustomerId = function (customerId) {
        var currentCustomer = this.readClientData("global-prisma-customer-id");
        this.customer = customerId;
        this.api.customer = customerId;
        //when customer changes as part of an operation(banner or popup) is because a merge ocurred
        if (customerId != currentCustomer) {
            this.api.anonymous = false;
            this.storeClientData("prisma-customer-anon", "0", 365 * 24 * 60 * 60);
            this.storeClientData("global-prisma-customer-id", this.customer, 365 * 24 * 60 * 60);
        }
    };

    Prisma.prototype.createPopup = function(popupInfo) {
        var self = this;
        var trackingToken = popupInfo.at(jsedn.kw(":tracking-token"));
        var campaign_id =  popupInfo.at(jsedn.kw(":campaign"));
        var showClose =  popupInfo.at(jsedn.kw(":show-close"));
        var funnel = popupInfo.exists(jsedn.kw(":funnel"))
                                      ? new Funnel(self,
                                                   self.customer,
                                                   popupInfo.at(jsedn.kw(":campaign")),
                                                   self,
                                                   popupInfo.at(jsedn.kw(":funnel")),
                                                   trackingToken) : null;
        var p = new prisma.IFramePopup();
        var landingUrl = self.baseUrl() + "/api/campaigns/" + campaign_id + "/funnel?d="
                                        + trackingToken + "&a="+self.api.appToken+"&t=" + self.tracker.currentTrail + "&popup=true";
        var dismissFn = funnel && funnel.funnel.allowDismiss ? function() {
            funnel.dismiss();
            p.hide();
        } : null;

        var closePopupEventsHandler = function (event) {

            if(event.data){
                if (event.data.eventType === "dismiss") {
                    if (dismissFn)
                        dismissFn();
                    removeEventListener("message", closePopupEventsHandler);
                }else if(event.data ===  "confirmed" || event.data === "cancelled"){
                    p.hide();
                    removeEventListener("message", closePopupEventsHandler);
                }
            }
        }

        window.addEventListener("message", closePopupEventsHandler);

        p.show(landingUrl, popupInfo.at(jsedn.kw(":width")), popupInfo.at(jsedn.kw(":height")), dismissFn, !showClose);
        var autoClose = popupInfo.at(jsedn.kw(":close-automatically"));
        if (autoClose) {
          var closeTimeout = popupInfo.at(jsedn.kw(":close-timeout"));
          var close_timeout_fn = setTimeout(function(){
              p.hide();
          }, closeTimeout);
        }
    };

    Prisma.prototype.bannersReady = function() {
        for (var b in this.banners) {
            if (this.banners.hasOwnProperty(b)) {
                if (this.banners[b].images == undefined) {
                    return false;
                }
            }
        }
        return true;
    };

    prisma.Funnel = Funnel;
    prisma.PopUp = PopUp;
    prisma.IFramePopup = IFramePopup;
    prisma.Tracker = Tracker;
    prisma.Prisma = Prisma;
    prisma.setEvent = setEvent;
    prisma_prisma = Prisma;
    prisma.plugins = prisma.plugins || {};
    prisma.registerPlugin = function(id, baseClass,superClass) {
        inherit(baseClass, superClass || View);
        prisma.plugins[id] = baseClass;
    };
    prisma.startFunnel = startFunnel;
    prisma.closePopup = closePopup;
    prisma.dismissCampaign = dismissCampaign;

    prisma.findPlugin = function(id) {
        return prisma.plugins[id];
    };

    prisma.openWindow = function(url, target) {
      if (window.cordova && window.cordova.InAppBrowser) {
        window.cordova.InAppBrowser.open(url, '_system');
      } else {
        window.open(url, target);
      }
    }

    prisma.openFunnelPage = function(funnel, landingUrl) {
        if (funnel.newWindow) {
          prisma.openWindow(landingUrl);
        } else {
            window.top.location.href = landingUrl + ("&callback-url=" + encodeURIComponent(window.top.location.href));
        }
    };

    prisma._T = function(sectionName, key, translations) {
        var translations = translations ? translations : prisma.client.globalTranslations;
        if (translations){
            section = translations[sectionName];
            if (section) {
                return section[key];
            }
        }
        return "";
    };

    prisma.libs = function() {
        if (prisma.mockup == true){
          return [{url:"css/prismaWeb.css?v=9.2.398&_=1738782455",
                   type: "css"},
                  {check: "jsedn",
                   url:"scripts/jsedn.js?v=9.2.398&_=1738782455"},
                  {check:"prisma_api",
                   url:"scripts/mockup_api.js?v=9.2.398&_=1738782455"},
                  {check:"prisma_plugins",
                   url:"scripts/message.js"}];
        }
        if (prisma.sandboxHash != null
            || prisma.enableEasyXDM != true
            || prisma.inLandingPage != null
            || window.location.protocol == "file:" // iOS embedded UIWebView
            || window.location.protocol == "capacitor:" // iOS Ionic + Capacitor embedded WKWebView
            || window.location.protocol == "httpsionic:" // iOS Ionic + Cordova embedded WKWebView
            || window.location.protocol == "ionic:") { // iOS Ionic + Cordova embedded WKWebView
          var libs = [{url:"sdk/stylesheets/prismaWeb.css?v=9.2.398&_=1738782455",
                      type: "css"},
                     {check: "jsedn",
                      url:"scripts/jsedn.js?v=9.2.398&_=1738782455"},
                     {check:"prisma_api",
                      url:"sdk/javascript/xmlhttp_api.js?v=9.2.398&_=1738782455"},
                     {check:"prisma_textinput_mask",
                      url:"sdk/javascript/textinput-mask.js?v=9.2.398&_=1738782455"},
                     {check:"prisma_plugins",
                      url:"sdk/javascript/plugins.js?v=9.2.398&_=1738782455"}];
          if (!window.atob) {
              libs.unshift({check: "Base64",
                            url: "sdk/javascript/base64.js?v=9.2.398&_=1738782455"});
          }
          return libs;
        }
        // normal browser with crossdomain support
        var libs= [{url:"sdk/stylesheets/prismaWeb.css?v=9.2.398&_=1738782455",
                   type: "css"},
                  {check: "JSON",
                   url: "scripts/json2.js?v=9.2.398&_=1738782455"},
                  {check: "easyXDM",
                   url: "scripts/easyXDM.min.js?v=9.2.398&_=1738782455"},
                  {check: "jsedn",
                   url:"scripts/jsedn.js?v=9.2.398&_=1738782455"},
                  {check: "prisma_api",
                   url: "sdk/javascript/api.js?v=9.2.398&_=1738782455"},
                  {check:"prisma_textinput_mask",
                   url:"sdk/javascript/textinput-mask.js?v=9.2.398&_=1738782455"},
                  {check:"prisma_plugins",
                   url:"sdk/javascript/plugins.js?v=9.2.398&_=1738782455"}];
          if (!window.atob) {
              libs.unshift({check: "Base64",
                            url: "sdk/javascript/base64.js?v=9.2.398&_=1738782455"});
          }
          return libs;
      };

    prisma.scriptsAlreadyLoaded = function() {
        return typeof jsedn == "object";
    };
    prisma.cssIsLoaded = function (cssPath) {
        var ss = document.styleSheets;
        for (var i = 0, max = ss.length; i < max; i++) {
            if (ss[i].href == cssPath)
                return true;
        }
        return false;
    }

    prisma.loadCss = function (cssref) {
        if (!prisma.cssIsLoaded(cssref)) {
            var fileref = document.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", cssref);
            document.getElementsByTagName("head")[0].appendChild(fileref);
        }
    }

    prisma.loadScript = function (url,waitfor,callback) {
        if (!waitfor || (waitfor in window == false && waitfor in window.prisma == false)) {
          var it;
          var node = document.createElement('script');
          node.type = 'text/javascript';
          node.async = true;
          node.src = url;
          node.onerror = function () {
            clearInterval(it);
            if (errorCallback) {
              errorCallback();
            }
          };
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(node, s);
          if (waitfor){
            it = setInterval(function(){
                    if(waitfor in window || waitfor in window.prisma){
                        clearInterval(it);
                        callback(true);
                    }
                 },20);
           }else
              callback(true);
        }else
            callback(true);
    };
    function api_setup() {
        if (prisma.sandboxHash != null
            || prisma.enableEasyXDM != true
            || prisma.inLandingPage != null
            || window.location.protocol == "file:" // iOS embedded UIWebView
            || window.location.protocol == "capacitor:" // iOS Ionic + Capacitor embedded WKWebView
            || window.location.protocol == "httpsionic:" // iOS Ionic + Cordova embedded WKWebView
            || window.location.protocol == "ionic:") { // iOS Ionic + Cordova embedded WKWebView
            // Use xmlhttp_api
            prisma.api = prisma_xmlhttp_api;  //defined globally by xmlhttp_api.js
        } else {
            //use default api and easy-xdm
            prisma.api = prisma_default_api; //defined globally by api.js
        }
    }
    prisma.loadScripts = function (base, cb, errorCallback) {
      var scriptslist = this.libs();
      var load_next = function(){
        var t = scriptslist.shift();
        if (t) {
          if(t.type === "css"){
            prisma.loadCss(base + t.url);
            load_next();
          } else {
            prisma.loadScript(t.extern ? t.url : base + t.url,
                              t.check,
                              function (status){
                                if(!status){
                                    if (errorCallback) {
                                        errorCallback();
                                    }
                                }else {
                                    load_next();
                                }
                              });

          }
        } else {
           api_setup();
           cb();
        }
      };
      load_next();
    };

    prisma.convert = function(server, port, appToken, proto, campaignId, callback) {
      var api = new prisma.api(server, port, appToken, proto);
      var tracker = prisma.client.tracker;
      function poststep(api, name, type, campaignId, onSuccess, onError) {
        var url = prisma.format("/api/traces/trail/{0}/action", tracker.currentTrail);
        var parameters = {"campaign": campaignId};

        var query = new jsedn.Map([jsedn.kw(":name"), name,
          jsedn.kw(":type"), jsedn.kw(":trail-action/" + type),
          jsedn.kw(":is-identification"), false]);

        var data = [];
        for (var p in parameters) {
          if (parameters.hasOwnProperty(p)) {
            data.push(new jsedn.Map([jsedn.kw(":name"), p,
              jsedn.kw(":value"), parameters[p]]));
          }
        }
        query.set(jsedn.kw(":data"), data);
        api.post(url, query, onSuccess, onError);
      }

      var resetCookies = function(response) {
        if (tracker.isValid()) {
          tracker.reset();
          if (callback) {
              callback(response);
          }
        } else {
          var waiter = setInterval(function() {
            if (tracker.isValid()) {
              tracker.reset();
              if (callback) {
                  callback(response);
              }
              clearInterval(waiter);
            }
          }, 100);
        }
      };
      poststep(api, "funnel-start", "funnel-step", campaignId,
        function (response) {
          poststep(api, "conversion", "conversion", campaignId, resetCookies, resetCookies)
        }, resetCookies);
    }

    prisma.load = function(server, port, appToken, customer, banners, proto, opts) {
      var self = this;
      var proto = proto || window.location.protocol;
      var basePath = prisma.mockup ?  "" : proto +"//"+server+":"+port+"/";
      customer = validCustomerId(customer); //sanitize customer, cannot be null.

      var notifyLoaded = function(loaded, error) {
          var instance = this;
          if (loaded && opts && opts.onLoaded != undefined) {
              opts.onLoaded.call(instance, instance.banners);
          }
          if (!loaded && opts && opts.onLoadFailed != undefined) {
            opts.onLoadFailed.call(instance, error);
         }
      };

      //multiple iframe pivoting works only if cookies work, not on cordova or other frameworks
      var loading = readCookie("prisma-loading");
      if (!loading && (!prisma.client || (prisma.client && prisma.client.tracker.isValid()))) {
         createCookie("prisma-loading", "true", 30);
         prisma.loadScripts(basePath,
           function() {
             var i = new prisma.Prisma(proto, server, port, appToken, customer, opts);
             // hack to not override global translations when multiple sync page are called on the same page.
             i.globalTranslations = prisma.client && prisma.client.globalTranslations ? prisma.client.globalTranslations : null;
             i.loadedOnPage = true;
             prisma.client = i;
             i.notifyLoaded = notifyLoaded;
             if (i.tracker.isValid()) {
                  createCookie("prisma-loading", "", -1);
                  i.customer = i.tracker.customer;
                  i.tracker.trackLocation();
                  i.syncPage(banners);
                  //i.startWatcher();
             } else {
                var waiter = setInterval(function() {
                      if (i.tracker.isValid()) {
                          createCookie("prisma-loading", "", -1);
                          i.customer = i.tracker.customer;
                          i.tracker.trackLocation();
                          clearInterval(waiter);
                          i.syncPage(banners);
                          //i.startWatcher();
                      } else if (i.tracker.didFailToInitialize()) {
                        createCookie("prisma-loading", "", -1);
                        clearInterval(waiter);
                        notifyLoaded(false, i.tracker.trackerInitError);
                      }
                   }, 100);
               }
           }, function () {
             createCookie("prisma-loading", "", -1);
           });
      } else {
        //it seems that tracker is not valid yet, probably it's initializing the cookies.
        var waiter = setInterval(function() {
          //if loading is being done in another iframe there's no prisma.client
          //so check for a cookie set by another window
          var currentTrace = readCookie("prisma-trace-id");
          if (currentTrace || (prisma.client && prisma.client.tracker.isValid())) {
            prisma.load(server, port, appToken, customer, banners, proto, opts);
            clearInterval(waiter);
          }
        }, 50);
      }
    };

    prisma.init = function(opts){
      prisma.load(opts.server, opts.port, opts.appToken, opts.customer, opts.banners, opts.proto, opts.opts);
    };
    prisma.isMobile = isMobile;
})();
